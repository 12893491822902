import cloneDeep from "lodash/cloneDeep";
import mapValues from "lodash/mapValues";

export function helperActionsFactory(actions) {
  return mapValues(actions, (action) => ({ getters, dispatch }, control) => {
    const controls = cloneDeep(getters.selectedControls);
    const anchorControl = cloneDeep(control);

    controls.forEach((control) => action({ control, getters, anchorControl }));

    dispatch("updateControls", { controls });
  });
}
