import { helperActionsFactory } from "./helpers";

export const actions = {
  /**
   * @typedef {Object} ActionParams
   * @property {Object} control Target control
   * @property {Object} getters Store getters
   * @property {Object} anchorControl Reference control for the alignment
   */
  /**
   * Centralize control in both axis
   *
   * @param {ActionParams} params Action parameters
   */
  centralize({ control, getters }) {
    const canvasWidth = parseInt(getters.panel.options.canvas.style.width);
    const canvasHeight = parseInt(getters.panel.options.canvas.style.height);

    const controlWidth = control.synopticComponent.clientRect.width;
    const controlHeight = control.synopticComponent.clientRect.height;

    const left = Math.round(canvasWidth / 2) - Math.round(controlWidth / 2);
    const top = Math.round(canvasHeight / 2) - Math.round(controlHeight / 2);

    control.synopticComponent.clientRect.left = left;
    control.synopticComponent.clientRect.top = top;
  },
  /**
   * Centralize control in horizontal axis
   *
   * @param {ActionParams} params Action parameters
   */
  centralizeHorizontally({ control, getters }) {
    const canvasWidth = parseInt(getters.panel.options.canvas.style.width);
    const controlWidth = control.synopticComponent.clientRect.width;
    const left = Math.round(canvasWidth / 2) - Math.round(controlWidth / 2);

    control.synopticComponent.clientRect.left = left;
  },
  /**
   * Centralize control in vertical axis
   *
   * @param {ActionParams} params Action parameters
   */
  centralizeVertically({ control, getters }) {
    const canvasHeight = parseInt(getters.panel.options.canvas.style.height);
    const controlHeight = control.synopticComponent.clientRect.height;
    const top = Math.round(canvasHeight / 2) - Math.round(controlHeight / 2);

    control.synopticComponent.clientRect.top = top;
  },
  /**
   * Align selected controls bottom edge with {anchorControl} bottom edge
   *
   * @param {ActionParams} params Action parameters
   */
  alignBottom({ control, anchorControl }) {
    if (control.id == anchorControl.id) return;

    const controlHeight = control.synopticComponent.clientRect.height;
    const top =
      anchorControl.synopticComponent.clientRect.top -
      (controlHeight - anchorControl.synopticComponent.clientRect.height);

    control.synopticComponent.clientRect.top = top;
  },
  /**
   * Align selected controls top edge with {anchorControl} top edge
   *
   * @param {ActionParams} params Action parameters
   */
  alignTop({ control, anchorControl }) {
    if (control.id == anchorControl.id) return;

    control.synopticComponent.clientRect.top =
      anchorControl.synopticComponent.clientRect.top;
  },
  /**
   * Align selected controls right edge with {anchorControl} right edge
   *
   * @param {ActionParams} params Action parameters
   */
  alignRight({ control, anchorControl }) {
    if (control.id == anchorControl.id) return;

    const controlWidth = control.synopticComponent.clientRect.width;
    const left =
      anchorControl.synopticComponent.clientRect.left -
      (controlWidth - anchorControl.synopticComponent.clientRect.width);

    control.synopticComponent.clientRect.left = left;
  },
  /**
   * Align selected controls left edge with {anchorControl} left edge
   *
   * @param {ActionParams} params Action parameters
   */
  alignLeft({ control, anchorControl }) {
    if (control.id == anchorControl.id) return;

    control.synopticComponent.clientRect.left =
      anchorControl.synopticComponent.clientRect.left;
  },
  /**
   * Align selected controls vertical centers with {anchorControl} vertical center
   *
   * @param {ActionParams} params Action parameters
   */
  alignHorizontally({ control, anchorControl }) {
    if (control.id == anchorControl.id) return;

    const controlHeight = control.synopticComponent.clientRect.height;
    const top =
      anchorControl.synopticComponent.clientRect.top -
      (controlHeight - anchorControl.synopticComponent.clientRect.height) / 2;

    control.synopticComponent.clientRect.top = top;
  },
  /**
   * Align selected controls horizontal centers with {anchorControl} horizontal center
   *
   * @param {ActionParams} params Action parameters
   */
  alignVertically({ control, anchorControl }) {
    if (control.id == anchorControl.id) return;

    const controlWidth = control.synopticComponent.clientRect.width;
    const left =
      anchorControl.synopticComponent.clientRect.left -
      (controlWidth - anchorControl.synopticComponent.clientRect.width) / 2;

    control.synopticComponent.clientRect.left = left;
  }
};

export default helperActionsFactory(actions);
