import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import { merge } from "lodash";
Vue.use(VueResource);
/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
  */

const baseurl = ""; //v1  set to "" after conversion

export default class DataService {
  /*
  * @param: id the contract_id
  
  returned object:
  {
    id: 1
    state: "Contratado"
    maximum_connectors: 1000
    registered_connectors: 39
    maximum_third_party_connectors: 1000
    registered_third_party_connectors: 0
    maximum_data: 10000
    registered_data: 1154
    maximum_alarms: 10000
    registered_alarms: 678
    maximum_histories: 10000
    registered_histories: 1149
    allowed_remote_access: true
    allowed_telegram_integration: true
    allowed_api_rest: true
  }
  */
  contractAdapter(contract) {
    let resources = [
      {
        name: "devices",
        text: "device_plural",
        quota: { value: 0, source: "maximum_connectors" },
        usage: { value: 0, source: "registered_connectors" },
        yesno_question: false,
        hint: "maximum_number_of_devices_that_can_connect_to_the_portal"
      },
      {
        name: "third_party_devices",
        text: "third_party_devices",
        quota: { value: 0, source: "maximum_third_party_connectors" },
        usage: { value: 0, source: "registered_third_party_connectors" },
        yesno_question: false,
        hint:
          "maximum_number_of_third_party_devices_that_can_connect_to_the_portal"
      },
      {
        name: "data_plural",
        text: "data_plural",
        quota: { value: 0, source: "maximum_data" },
        usage: { value: 0, source: "registered_data" },
        yesno_question: false,
        hint: "maximum_number_of_data_that_can_be_stored_by_the_portal"
      },
      {
        name: "alarm_plural",
        text: "alarm_plural",
        quota: { value: 0, source: "maximum_alarms" },
        usage: { value: 0, source: "registered_alarms" },
        yesno_question: false,
        hint: "total_number_of_alarms_that_can_be_configured_on_the_portal"
      },
      {
        name: "history",
        text: "history",
        quota: { value: 0, source: "maximum_histories" },
        usage: { value: 0, source: "registered_histories" },
        yesno_question: false,
        hint: "reserved_number_of_data_with_history"
      },
      {
        name: "remote_access",
        text: "remote_access",
        quota: { value: 0, source: "allowed_remote_access" },
        usage: { value: 0, source: "allowed_remote_access" },
        yesno_question: true,
        hint: "enables_remote_access"
      },
      {
        name: "telegram_integration",
        text: "telegram_integration",
        quota: { value: 0, source: "allowed_telegram_integration" },
        usage: { value: 0, source: "allowed_telegram_integration" },
        yesno_question: true,
        hint: "enables_disconnection_and_alarm_notifications_by_telegram"
      },
      {
        name: "api_rest",
        text: "api_rest",
        quota: { value: 0, source: "allowed_api_rest" },
        usage: { value: 0, source: "allowed_api_rest" },
        yesno_question: true,
        hint:
          "enables_access_to_the_rest_api_for_developers_to_create_their_own_solution_based_on_portal_data"
      },
      {
        name: "text_list",
        text: "text_list",
        quota: { value: 0, source: "allowed_text_list" },
        usage: { value: 0, source: "allowed_text_list" },
        yesno_question: true,
        hint: "enables_data_value_customization_with_text_assignment"
      },
      {
        name: "geolocation",
        text: "geolocation",
        quota: { value: 0, source: "allowed_gmaps" },
        usage: { value: 0, source: "allowed_gmaps" },
        yesno_question: true,
        hint: "enable_connector_geolocation_map_view"
      },
      {
        name: "connector_models",
        text: "connector_models",
        quota: { value: 0, source: "allowed_model_connectors" },
        usage: { value: 0, source: "allowed_model_connectors" },
        yesno_question: true,
        hint: "enables_connector_models"
      },
      {
        name: "custom_screens",
        text: "custom_screens",
        quota: { value: 0, source: "allowed_custom_screens" },
        usage: { value: 0, source: "allowed_custom_screens" },
        yesno_question: true,
        hint: "enables_custom_screens"
      }
    ];
    for (var i in resources) {
      let resource = resources[i];
      if (resource.quota.source in contract) {
        resource.quota.value = contract[resource.quota.source];
      }
      if (resource.usage.source in contract) {
        resource.usage.value = contract[resource.usage.source];
      }
    }
    let billing = {
      resources: resources,
      plan: null
    };
    if ("contract_plan" in contract && contract.contract_plan) {
      billing.plan = {
        name: contract.contract_plan.name || "",
        amount_to_be_paid: contract.amount_to_be_paid || "",
        balance_amount: contract.balance_amount || "",
        minimum_value: contract.minimum_value || "",
        base_value: contract.base_value || "",
        plan_value_fee: contract.plan_value_fee || ""
      };
    }
    // validate the demo contract
    let demo_contract_id =
      (Vue?.http?.options?.config &&
        Vue.http.options.config.contract_demo_id) ||
      "";
    contract.is_demo =
      contract.is_demo ||
      (demo_contract_id && contract.id == demo_contract_id) ||
      false;
    contract.billing = billing;
    if (Vue?.http?.options?.config) {
      // Overwrite non standard extended equipment properties
      let properties =
        contract?.portal_data?.equipment_extended_properties || [];
      if (properties?.length) {
        let entry = {};
        (properties || []).forEach((field) => {
          if (typeof field == "object" && field?.name) {
            entry[field.name] = "value" in field ? field.value : "";
          }
        });
        Vue.http.options.config.equipment_extended_properties = {
          ...(Vue.http.options.config.equipment_extended_properties || {}),
          ...entry
        };
      }
      if (contract?.portal_data?.equipment_extended_page) {
        Vue.http.options.config.equipment_extended_page =
          contract.portal_data.equipment_extended_page;
      }
      // overwrite default global references for expressions
      // not yet available for modifications
      Vue.http.options.config.js_global_references = {
        ...{
          $value: "data?.current_value?.value||null",
          $connectorName: "data?.device?.connector?.name||''",
          $deviceName: "data?.device?.name||''",
          $fahrenheit: "data?.current_value?.value*1.8+32" // for reference only
        },
        ...(contract?.portal_data?.js_global_references || {})
      };

      // add default alarm severity to the contract portal data:
      contract.portal_data = contract?.portal_data || {};
      contract.portal_data.alarm_level_options =
        contract.portal_data.alarm_level_options || [];
      (Vue.http.options.config?.alarm.levels || []).forEach((option) => {
        var entry = JSON.parse(JSON.stringify(option));
        entry.default = true;
        let ix = contract.portal_data.alarm_level_options.findIndex(
          ({ label }) => label.value == option.label.value
        );
        if (ix >= 0) {
          contract.portal_data.alarm_level_options[ix] = merge(
            entry,
            contract.portal_data.alarm_level_options[ix]
          );
        } else {
          contract.portal_data.alarm_level_options.push(entry);
        }
      });
    }
    return contract;
  }

  /*
  get the profile contract or its resources
  */
  async get(id) {
    let self = this;
    return new Promise((resolve) => {
      let auth = new Auth();
      let url = `${baseurl}contracts/${id}/?format=json`; //v1
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          var data = null;
          if (response && response.body) {
            data = response.body;
            data = self.contractAdapter(data);
            resolve(data);
            return;
          }
          resolve(null);
        },
        (error) => {
          if (error.status == 403) {
            let url = `${baseurl}contracts_resources/${id}/?format=json`; //v1
            Vue.http.get(url, auth.requestOptions()).then(
              (response) => {
                var data = null;
                if (response && response.body) {
                  data = response.body;
                  data = self.contractAdapter(data);
                  resolve(data);
                  return;
                }
                resolve(null);
              },
              (error) => {
                //console.log(error);
                resolve(null);
              }
            );
          }
          else {
            resolve(null);
          }
        }
      );
    });
  }

  async fetchPrice(payload) {
    let self = this;
    return new Promise((resolve, reject) => {
      let url = `${baseurl}quotation/`; //v1
      let auth = new Auth();
      let request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response?.body?.quotation) {
            resolve(response.body);
          } else {
            reject(null);
          }
        },
        (error) => {
          //console.log(error);
          let body = error.body || {};
          if (body.detail) reject(body.detail);
          else {
            let msg = [];
            for (var i in body) {
              msg.push(i + ": " + body[i]);
            }
            reject(msg.join("\n"));
          }
        }
      );
    });
  }

  async updateContract(payload) {
    return new Promise((resolve, reject) => {
      let url = `${baseurl}contracts/`; //v1
      let auth = new Auth();
      let request = null;
      if ("id" in payload && payload.id) {
        request = Vue.http.patch(
          url + payload.id + "/",
          payload,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        );
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response?.body?.id) {
            resolve(response.body);
          } else {
            reject(null);
          }
        },
        (error) => {
          //console.log(error);
          if (error.status && error.statusText) {
            reject({
              status: error.status,
              statusText: error.statusText,
              message: error?.body?.detail ? error.body.detail : null
            });
          } else {
            let body = error.body || {};
            if (body.detail) {
              reject(body.detail);
            } else {
              let msg = [];
              for (var i in body) {
                msg.push(i + ": " + body[i]);
              }
              reject(msg.join("\n"));
            }
          }
        }
      );
    });
  }

  async history(query) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}contracts_transactions/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async getUsage(contractId) {
    let options = new Auth().requestOptions();
    let url = `${baseurl}contracts/${contractId}/usage/`;
    try {
      let response = await Vue.http.get(url, options);
      return response.body;
    } catch (e) {
      throw new Error(e);
    }
  }
}
