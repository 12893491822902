import Vue from "vue";
import CommandService from "../services/command.js";

/*
internal command:
var entry={
    "origin": payload,
    "command": response,
    "id": response.id,
    "state": "busy" | success | canceled | error"
} 
*/
function initialState() {
  return {
    monitor: false,
    isLoading: false,
    saveStatus: "ready",
    commandList: [] //
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    IS_LOADING(state, option) {
      state.isLoading = option;
    },
    SET_SAVE_STATUS(state, option) {
      state.saveStatus = option; // 'ready' | 'busy' | 'error' | 'success'
    },
    ADD_COMMAND(state, entry) {
      let self = this;
      var lst = state.commandList;
      lst.push(entry);
      Vue.set(state, "commandList", lst);
      self.dispatch("command/validate", entry.id);
    },
    DEL_COMMAND(state, entry) {
      let self = this;
      var lst = state.commandList.filter(function(i) {
        return i.id != entry.id;
      });
      Vue.set(state, "commandList", lst);
    },
    UPD_COMMAND(state, entry) {
      let self = this;
      var lst = state.commandList;
      for (var i in lst) {
        if (lst[i].id == entry.id) {
          lst[i].command = entry;
          lst[i].state = entry.state;
          break;
        }
      }
      Vue.set(state, "commandList", lst);
    }
  },
  actions: {
    /*
        Loads all pending commands
        */
    load({ state, commit, rootState }) {
      let references = Vue.http.options.config.references;
      let srv = new CommandService();
      let queue = [
        srv.fetch({ command_state_id: references.command_states.waiting }),
        srv.fetch({ command_state_id: references.command_states.busy }),
        srv.fetch({ command_state_id: references.command_states.canceled })
      ];
      Promise.all(queue).then(function(response) {
        for (var i in response) {
          if (response[i] && response[i].length) {
            for (var j in response[i]) {
              let command = response[i][j];
              if (
                command.portal_data &&
                command.portal_data.entry &&
                command.portal_data.entry.id
              ) {
                var entry = {
                  command: command,
                  id: command.id,
                  state: "busy"
                };
                commit("ADD_COMMAND", entry);
              }
            }
          }
        }
      });
    },
    reset({ state, commit, rootState }) {
      commit("RESET");
    },
    save({ state, commit, rootState }, payload) {
      let monitor = state.monitor;
      return new Promise((resolveResult) => {
        commit("SET_SAVE_STATUS", "busy");
        var threads = (payload || []).map(function(item) {
          return new Promise((resolve, reject) => {
            let srv = new CommandService();
            srv
              .save(item)
              .then((response) => {
                if (monitor && response && "id" in response) {
                  if (item.cancel_command) {
                    commit("DEL_COMMAND", item);
                  } else {
                    var entry = {
                      command: response,
                      id: response.id,
                      state: "busy"
                    };
                    commit("ADD_COMMAND", entry);
                  }
                  resolve(entry);
                } else {
                  if (item.cancel_command) {
                    commit("DEL_COMMAND", item);
                  }
                  resolve(response);
                }
              })
              .catch(reject);
          });
        });
        Promise.allSettled(threads).then(function(result) {
          commit(
            "SET_SAVE_STATUS",
            result.every((p) => p.status == "fulfilled") ? "success" : "error"
          );
          resolveResult(
            result.map((p, index) => ({
              ...p,
              value: p.value || payload[index]
            }))
          );
        });
      });
    },
    validate({ state, commit, rootState }, id) {
      let self = this;
      let srv = new CommandService();
      srv.get(id).then((response) => {
        if (response) {
          var lst = state.commandList.filter(function(i) {
            return i.id == response.id;
          });
          /* hardcoded constants */
          // Vue.http.options.config.references.command_states={"waiting":0,"busy":1,"ready":2,"canceled":3};
          // references.command_results={"success":1,"error":2};
          if (lst && lst.length) {
            let references = Vue.http.options.config.references;
            var entry = response;
            if (entry.command_state.id == references.command_states.ready) {
              // command finished
              if ("command_result" in entry) {
                entry.state =
                  entry.command_result == references.command_results.success
                    ? "success"
                    : "error";
                commit("UPD_COMMAND", entry);
              }
            } else if (
              entry.command_state.id == references.command_states.canceled
            ) {
              // command canceled
              commit("DEL_COMMAND", entry);
            } else {
              // command still pending
              setTimeout(function() {
                self.dispatch("command/validate", entry.id);
              }, 5000);
            }
          }
        }
      });
    }
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    saveStatus(state) {
      return state.saveStatus;
    },
    commandList(state) {
      return state.commandList;
    },
    status(state) {
      // returns a status command
      return (id, entry_type) => {
        var lst = state.commandList.filter(function(i) {
          return (
            i.id == id ||
            (i.command.portal_data &&
              i.command.portal_data.entry &&
              i.command.portal_data.entry.id == id &&
              (entry_type
                ? i.command.portal_data.entry.type == entry_type
                : true))
          );
        });
        return (lst && lst.length && lst[lst.length - 1].state) || null;
      };
    },
    waitingEntries({ state, commit, rootState }, self) {
      // Returns a list of ID that is still being waiting....
      return (lst, entry_type) => {
        let res = (lst || []).filter(function(id) {
          let status = self.status(id, entry_type);
          if (!status || status == "success" || status == "error") {
            return false;
          }
          return true;
        });
        return res;
      };
    }
  }
};
