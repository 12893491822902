const initState = () => ({
  count: undefined, // count
  sum: undefined, // sum
  minimum: undefined, // minimum
  maximum: undefined, // maximum
  average: undefined, // average
  diff: undefined, // difference (last value - first value)
  balance: undefined, // difference (last value - first value) || first value if length==1
  amplitude: undefined, // amplitude (max-min), // TODO: Must find a better name!!!
  first: undefined, // first sample,
  last: undefined, // last sample,
  mode: undefined, // mode
  median: undefined, // median,
  variance0: undefined, // variance based on population
  variance1: undefined, // variance based on samples
  stdev0: undefined, // standard Deviation based on population
  stdev1: undefined, // standard Deviation based on samples,
  freq: {},
  lst: [],
  maxFreq: 0,
  modeLst: [],
  isNumber: false
});

export default class Stats {
  constructor() {
    const m = moment;
    this.ranges = {
      today: [m().startOf("day")._d.getTime(), m()._d.getTime()],
      yesterday: [m().subtract(1, "days").startOf("day")._d.getTime(), m().subtract(1, "days").endOf("day")._d.getTime()],
      last_hour: [m().subtract(1, "hours").startOf("hour")._d.getTime(), m().subtract(1, "hours").endOf("hour")._d.getTime()],
      last_24_hours: [m().subtract(24, "hours")._d.getTime(), m()._d.getTime()],
      last_7_days: [m().subtract(6, "days").startOf("day")._d.getTime(), m()._d.getTime()],
      last_30_days: [m().subtract(29, "days").startOf("day")._d.getTime(), m()._d.getTime()],
      this_hour: [m().startOf("hour")._d.getTime(), m()._d.getTime()],
      this_month: [m().startOf("month")._d.getTime(), m()._d.getTime()],
      last_month: [m().subtract(1, "month").startOf("month")._d.getTime(), m().subtract(1, "month").endOf("month")._d.getTime()],
      this_week: [m().weekday(0).startOf("day")._d.getTime(), m()._d.getTime()],
      last_week: [m().subtract(7, "days").weekday(0).startOf("day")._d.getTime(), m().subtract(7, "days").weekday(6).endOf("day")._d.getTime()],
    };
    this.weekday = {
      sunday: (time) => new Date(time).getDay() === 0,
      monday: (time) => new Date(time).getDay() === 1,
      tuesday: (time) => new Date(time).getDay() === 2,
      wednesday: (time) => new Date(time).getDay() === 3,
      thursday: (time) => new Date(time).getDay() === 4,
      friday: (time) => new Date(time).getDay() === 5,
      saturday: (time) => new Date(time).getDay() === 6
    };
  }

  assert(fname, time) {
    if (fname in this.ranges) {
      if (!time) return true;
      return time >= this.ranges[fname][0] && time <= this.ranges[fname][1];
    }
    if (fname in this.weekday) {
      if (!time) return true;
      return this.weekday[fname](time);
    }
    if (!isNaN(fname) && parseInt(fname) >= 1 && parseInt(fname) <= 31) {
      if (!time) return true;
      return (new Date(time).getDate()) === parseInt(fname);
    }
    return false;
  }

  median(lst) {
    const len = lst.length;
    if (len === 0) return undefined;
    const half = Math.floor(len / 2);
    lst.sort((a, b) => a - b);
    return (len % 2 !== 0) ? lst[half] : (lst[half - 1] + lst[half]) / 2;
  }

  variance(lst, avg) {
    // variance 0 = population, 1 = based on samples
    const len = lst.length;
    if (len === 0) return undefined;
    const sum = lst.reduce((a, v) => a + Math.pow(v - avg, 2), 0);
    return [sum / len, sum / (len - 1 != 0 ? len - 1 : len)];
  }

  addSample(s, vlr) {
    // vlr = item?.value ?? item;
    s.count = s.count ?? 0;
    s.sum = s.sum ?? 0;
    if (isNaN(Number(vlr))) {
      if (s.minimum === undefined || vlr < s.minimum) s.minimum = vlr;
      if (s.maximum === undefined || vlr > s.maximum) s.maximum = vlr;
    } else {
      s.isNumber = true;
      vlr = parseFloat(vlr);
      s.sum += vlr || 0;
      if (s.minimum === undefined || vlr < s.minimum) s.minimum = vlr;
      if (s.maximum === undefined || vlr > s.maximum) s.maximum = vlr;
    }
    s.first = s.count ? s.first : vlr;
    s.last = vlr;
    s.count += 1;
    s.lst.push(vlr); // median list
    s.freq[vlr] = (s.freq[vlr] || 0) + 1;
    if (s.freq[vlr] > s.maxFreq) s.maxFreq = s.freq[vlr];
    return s;
  }

  consolidate(s) {
    if (s.count && s.isNumber) {
      let modeLst = [];
      for (let vlr in s.freq) if (s.freq[vlr] === s.maxFreq) modeLst.push(Number(vlr));
      s.minimum = s.minimum === undefined ? "" : s.minimum;
      s.maximum = s.maximum === undefined ? "" : s.maximum;
      s.average = s.count > 0 ? s.sum / s.count : 0;
      s.diff = s.last - s.first;
      s.balance = s.count > 1 ? s.diff : s.first;
      s.amplitude = s.maximum - s.minimum;
      s.median = this.median(s.lst);
      let variance = this.variance(s.lst, s.average);
      s.variance0 = variance[0]; // based on population
      s.variance1 = variance[1]; // based on samples
      s.stdev0 = Math.sqrt(s.variance0); // based on population
      s.stdev1 = Math.sqrt(s.variance1); // based on samples
      s.mode = modeLst.length !== s.lst.length ? modeLst.length === 1 ? modeLst[0] : modeLst : undefined;
    }
    return s;
  }

  standardize(s) {
    for (var p in s || {}) if (s[p] === undefined) s[p] = "";
    return s;
  }

  calc(samples, fname) {
    let s = initState();
    try {
      if (!(samples || []).length || (fname && !this.assert(fname))) return s;
      (samples || []).forEach((item) => {
        item.time = item.time || new Date(item.date_time).getTime(); // virtual property
        // if (fname && !this.assert(fname, item.time || new Date(item.date_time).getTime())) this.standardize(s);
        if (fname && !this.assert(fname, item.time || new Date(item.date_time).getTime())) return;
        if (!isNaN(Number(item.value))) item.value = parseFloat(item.value);
        this.addSample(s, item?.value ?? item);
      });
      this.consolidate(s);
    } catch (error) {
      console.log(error);
    }
    samples = samples.sort((a, b) => a.time - b.time);
    return this.standardize(s);
  }
}
