import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import TextListService from "@/services/text-list.js";

Vue.use(VueResource);

const baseurl = ""; //v1  set to "" after conversion
/*
  
  Setup the initial app configuration
  It must available to all components instances thru:

  this.$root.config
  this.$root.config.references
  this.$root.config.references:{
    connector_types: (...)
    local_storage_versions: Array(2)
    data_memory_types: (...)
    data_value_source_types: (...)
    data_calculation_types: (...)
    data_value_format_types: (...)
    alarm_trigger_conditions: (...)
    alarm_states: (...)
  }
*/
const updateTextList = (query) => {
  return new Promise((resolve) => {
    let srv = new TextListService();
    srv.fetch(query).then((response) => {
      if (response && response.length) {
        Vue.http.options.config.references.text_lists = response;
      } else {
        Vue.http.options.config.references.text_lists = null;
      }
      resolve(Vue.http.options.config.references.text_lists);
    });
  });
};
export { updateTextList };

export default class Settings {
  async fetch(query) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let url = `${baseurl}settings/?format=json`; //v1
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          var data = (response && response.body) || null;
          resolve(data);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  updateTextList = updateTextList;

  async setup(query) {
    let self = this;
    return new Promise((resolve) => {
      self.fetch(query).then((response) => {
        if (response && Vue.http.options && Vue.http.options.config) {
          let references = response;
          // constants:
          references.command_types = { data_writing: 1, ack_writing: 2 };
          references.command_states = {
            waiting: 0,
            busy: 1,
            ready: 2,
            canceled: 3
          };
          references.command_results = { success: 1, error: 2 };
          // console.log(references.data_memory_types)
          references.data_memory_types.forEach((i) => {
            if (i.basic_type) {
              // based on embedded enum
              i.basic_type.type = {
                "1": "bool",
                "2": "int",
                "3": "float",
                "4": "string"
              }[i.basic_type.number];
            }
            // BEGIN test
            // TODO: Remove after test it.
            // 15 - Holding Register
            // 33 - Holding Register (sinalizado)
            // 17 - Float - 2 Holding Registers
            // 34 - MQTT number
            // if (i.id == 17 || i.id == 34) {
            //   i.vector_max_size = 20;
            // }
            // else {
            //   i.vector_max_size = 1;
            // }
            // END test
            // if (i.basic_type.type == 'string') {
            //   i.vector_max_size = 1; // TODO: Remove once array of strings has been proper implemented at API level
            //   i.max_length = 1;
            //   i.max_string_length = 123; // TODO: get it from the backend
            // }
          });
          Vue.set(Vue.http.options.config, "references", references);
          // Vue.http.options.config.references = references;
          //=============================
          self.updateTextList(query); //update text lists
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  updateUserPreferences(user) {
    // Extend the dashboard configuration with user properties
    let dashboard = {
      ...Vue.http.options.config.dashboard,
      ...user.user_profile.portal_data
    };
    Vue.set(Vue.http.options.config, "dashboard", dashboard);
  }
}
