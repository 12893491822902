import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "./auth.js";

Vue.use(VueResource);

export default class TaskService {
  /*

   https://api.dev.telemetria.webhi.com.br/rest/v1/alarms_history/?contract_id=10&start=2021-09-26T13%3A23%3A00&end=2022-09-26T13%3A23%3A00&download_file=true&return_format=csv&return_timezone=America%2FSao_Paulo

  operator:
    before: last values before date time (query.start => query.values_at) (before)
    between: samples within the start/end interval (between)
    after: next values after date time (query.end => query.values_at) (after)
    current: current data value
  */
  async fetchSamples(query, operator) {
    return new Promise((resolve, reject) => {
      let opt = {
        before: "data_before_value_at",
        between: "data_history",
        data_between: "data_history",
        alarms_between: "alarms_history",
        after: "data_after_value_at",
        current: "data_current_value"
      }[operator || "between"];
      if (!opt) {
        resolve([]);
        return;
      }
      let url = `${opt}/?format=json`;
      let auth = new Auth();
      if (query) {
        let params = JSON.parse(JSON.stringify(query));
        if (opt != "data_history" && opt != "alarms_history") {
          if (opt == "data_before_value_at") {
            params.value_at = params.start;
          } else if (opt == "data_after_value_at") {
            params.value_at = params.end;
          }
          delete params.start;
          delete params.end;
        }
        for (var prop in params) {
          let vlr = encodeURIComponent(params[prop]);
          // let vlr = params[prop];
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          if (reject) {
            reject(msg.join("\n"));
          } else {
            resolve(msg.join("\n"));
          }
        }
      );
    });
  }

  async checkTask(id, query) {
    return new Promise((resolve) => {
      let url = `async_tasks_results/${id}/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          if (typeof body == "string") {
            resolve(body);
          } else {
            let msg = [];
            for (var i in body) {
              msg.push(i + ": " + body[i]);
            }
            if (msg.length > 8) {
              resolve(msg.splice(8).join("\n"));
            } else {
              resolve(msg.join("\n"));
            }
          }
        }
      );
    });
  }
}
