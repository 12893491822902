export default {
  methods: {
    startTour(name) {
      const start = () => this.$tours[name]?.start?.();

      if (!this.$featureToggle.isFeatureEnabled(name)) {
        this.$featureToggle.setFeature(name, true);
      }

      // if passed tour doesn't have a element of class <name>
      if (!document.querySelector(`.${name}`)) {
        // creates a observer and awaits for
        // it to appear at the page
        let observer = new MutationObserver((mutations) => {
          if (mutations.find((m) => m.target.matches(`.${name}`))) {
            observer.disconnect();
            start();
          }
        });
        observer.observe(document.body, { childList: true, subtree: true });

        // disconnects observer after 5s if element is not found
        setTimeout(() => observer.disconnect(), 5000);
      }
      // starts anyway in case element is not needed
      start();
    },
    checkNotificationTour(notifiation) {
      if (notifiation.portal_data?.featureTour) {
        this.startTour(notifiation.portal_data.featureTour);
      }
    }
  },
  created() {
    this.$root.$on("tour:start", this.startTour);
    this.$root.$on("notification:open", this.checkNotificationTour);
  },
  beforeDestroy() {
    this.$root.$off("tour:start", this.startTour);
    this.$root.$off("notification:open", this.checkNotificationTour);
  }
};
