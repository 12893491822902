// prettier-ignore
export default {
  sign_in: "Entrar",
  sign_in_to_start_your_session: "Login para iniciar sua sessão",
  remember_me: "Lembrar senha",
  i_forgot_my_password: "Esqueci minha senha",
  register_a_new_membership: "Novo usuário",
  invalid_login_or_password: "Login ou senha inválidos",
  all: "Todos | Todas",
  connection: "Conexão",
  connections: "Conexões",
  connected: "Conectado | Conectados",
  disconnected: "Desconectado | Desconectados",
  disconnection: "Desconexão",
  alarm: "Alarme | Alarmes",
  alarms: "Alarmes",
  active: "Ativo",
  inactive: "Inativo",
  description: "Descrição",
  s_n: "S/N",
  status: "Estado",
  connection_status: "Estado",
  alarm_active: "Alarme Ativo",
  alarm_inactive: "Alarme Inativo",
  no_equipment_was_found: "Nenhum conector encontrado",
  equipment_list: "Conectores",
  sign_out: "Sair",
  profile: "Perfil",
  hours_alarms: "Horas de alarmes",
  hours_alarms_24: "Alarmes(24 horas)",
  days_runtime: "Dias funcionamento",
  perc_days_runtime_30: "Percentual Operação (30 dias)",
  next_maintenance: "Proxima Manutenção",
  days_next_maintenance: "Dias p/ proxima Manutenção",
  not_latest: "Not Latest",
  recent: "Recente",
  model: "Modelo",
  instance: "Instância|Instâncias",
  equipment: "Conector",
  equipment_not_selected: "Conector não selecionado",
  equipment_alarms: "Alarmes do conector ",
  n: "Nº",
  criteria: "Critério",
  timestamp: "Timestamp",
  value: "Valor",
  reference: "Referencia",
  level: "Nível|Níveis",
  there_are_no_alarms_configuration: "Não há alarmes configurados ou que correspondam aos critérios de seleção",
  list_can_not_be_displayed_in_editor_mode: "Lista não disponivel durante edição",
  there_are_no_alarms_in_the_selected_range: "Não existe notificação no periodo selecionado",
  data_history: "Histórico",
  main_navigation: "MENU",
  home: "Inicio",
  support: "Suporte",
  last_event: "Último evento",
  no_data_found: "Nenhum dado encontrado",
  customer: "Cliente",
  state: "Estado",
  city: "Cidade",
  supervisor: "Supervisor",
  region: "Região",
  calendar: {
    Su: "Do",
    Mo: "Se",
    Tu: "Te",
    We: "Qa",
    Th: "Qi",
    Fr: "Sx",
    Sa: "Sa",
    January: "Janeiro",
    February: "Fevereiro",
    March: "Março",
    April: "Abril",
    May: "Maio",
    June: "Junho",
    July: "Julho",
    August: "Agosto",
    September: "Setembro",
    October: "Outubro",
    November: "Novembro",
    December: "Dezembro",
    today: "Hoje",
    yesterday: "Ontem",
    last_24_hours: "Últimas 24 Horas",
    last_7_days: "Últimos 7 Dias",
    last_30_days: "Últimos 30 Dias",
    this_month: "Este Mês",
    last_month: "Mês anterior",
    input: "Input",
    button: "Botão",
    interval_range: "Intervalos pré-definidos"
  },
  apply: "Aplicar",
  apply_to: "Aplicar à",
  Cancel: "Cancelar",
  ok: 'Ok',
  From: "De",
  To: "Ate",
  Custom: "Customizar",
  edit: "Editar",
  there_are_no_data_configured: "Não existem dados configurados",
  there_are_no_items_configured: "Não existem itens configurados",
  unit: "Unidade",
  name: "Nome",
  options: "Opções",
  show_lines: "Mostrar linhas",
  do_not_show_lines: "Não mostrar linhas",
  there_are_no_data_to_show: "Não há dados para mostrar",
  data_selector: "Seletor de dados",
  data_configuration: "Configuração",
  data_editor: "Editor de dados",
  config: "Configurar",
  save: "Salvar",
  save_and_close: "Salvar e fechar",
  saving: 'Salvando',
  you_have_saved_n_items: `Você salvou {count} item(s)!`,
  you_have_deleted_n_items: `Você deletou {count} item(s)!`,
  you_have_restored_n_items: `Você restaurou {count} item(s)!`,
  item_could_not_be_saved: "Item não pode ser salvo",
  item_could_not_be_deleted: "Item não pode ser apagado",
  invalid_resources: 'Recursos inválidos',
  saved: "Salvo",
  deleted: "Removido",
  please_assign_the_title_of_the_property: "Por favor atribua o titulo da propriedade",
  component_not_found: "Componente não encontrado",
  invalid_component: "Componente Invalido",
  vue_component: "Vue Componente",
  no_user_rules_were_found: "Nenhuma regra de usuário foi encontrada",
  unauthorized: "Não autorizado",
  you_do_not_have_access_to_the_requested_resource: "Você não tem acesso ao recurso solicitado!",
  out_of_range: "Fora do intervalo",
  data_required: "Informação requerida",
  previous_value: "Valor anterior",
  confirm: "Confirmar",
  confirmed: "Confirmado",
  canceled: "Cancelado",
  execution: "Execução",
  verification: "Verificação",
  acknowledgement: "Reconhecimento",
  acknowledge: "Reconhecer",
  validate: "Validate",
  validating: "Validando",
  remark: "Observação",
  group: "Grupo | Grupos",
  permission_group: "Grupo de Permissões | Grupos de Permissões",
  new_group: "Novo grupo",
  select_a_group: "Selecione um grupo",
  create_a_new_group: "Crie um grupo",
  you_dont_have_any_group_yet: "Você não tem nenhum grupo ainda!",
  send_notifications_to: "Enviar notificações para",
  users_to_notify: "Usuários a serem notificados",
  emails: "E-mails",
  priority: "Prioridade",
  equipment_notifications: "Notificações por conector",
  alarm_notifications: "Notificações por alarme",
  wait_for_alarm_recognition_timeout: "Aguardar o reconhecimento do alarme por",
  before_try_a_new_group: "Antes de tentar um novo grupo",
  notification_configuration: "Configuração de Notificações",
  invalid_data: "Dado inválido",
  invalid_action: "Ação inválida",
  invalid_serial_number: "Número serial inválido",
  invalid_image: "Imagem inválida",
  invalid_name: "Nome inválido",
  user: "Usuário | Usuários",
  user_list: "Relação de usuários",
  username: "Usuário",
  full_name: "Nome completo",
  first_name: "Primeiro nome",
  last_name: "Sobrenome",
  password: "Senha",
  confirm_password: "Confirme a senha",
  roles: "Papéis (privilégios)",
  access_control: "Controle de Acesso",
  set_new_password: "Alterar senha",
  require_password_change: "Forçar troca de senha",
  notification: "Notificação | Notificações",
  email: "E-mail",
  cancel: "Cancelar",
  not_found: "Não encontrado",
  delete: "Deletar",
  erase: "Apagar",
  clone: "Clonar",
  a_clone: 'Clone',
  save_as: "Salvar como",
  create_copy: "Criar cópia",
  serial_number: "Número serial",
  serial_number_failover: "S/N do conector substituto",
  extended_properties: "Campos customizados",
  acknowledgement_execution_requested: "Execução de reconhecimento solicitado",
  unknown_error_while_executing_request: "Erro desconhecido ao executar a requisição",
  invalid_equipment_serial_number: "Numero serial invalido",
  new: "Novo | Nova",
  search: "Pesquisar",
  are_you_sure: "Tem certeza?",
  you_wont_be_able_to_revert_this: "Você não poderá reverter este processo",
  yes_delete_it: "Sim, delete",
  yes_proceed: "Sim, confirmar",
  plc: "Conector",
  new_equipment: "Novo Conector",
  not_selected: "Não selecionado",
  alarmed: "Em alarme",
  normal: "Normal",
  ack: "Rec.",
  not: "Not.",
  alarm_acknowledgement: "Reconhecimento de alarme",
  subevent: "Sub-evento",
  ack_required: "Reconhecimento Requerido",
  processing: "Processando",
  no_ack_required: "Reconhecimento não requerido",
  acknowledged_alarm: "Alarme reconhecido",
  item_not_saved: "Item não salvo",
  process_area: "Área de processo | Áreas de processo",
  select_the_process_area: "Selecione a área de processo",
  privileges: "Privilégios",
  process_area_name: "Nome da área de processo",
  process_area_description: "Descrição da área de processo",
  edit_this_item: "Editar este item",
  remove: "Remover",
  remove_permanently: "Remover permanentemente|Removido permanentemente",
  removing: "Removendo",
  remove_this_item: "Remover este item",
  not_allowed: "Não permitido",
  you_do_not_have_enough_privileges_to_execute_this_action: "Você não possui privilegios suficientes para executar esta ação",
  new_process_area: "Nova área de processo",
  user_profile: "Perfil do usuário",
  previous_password: "Senha anterior",
  new_password: "Nova Senha",
  this_operation_might_be_affecting_other_equipment: "Esta ação poderá afetar outros conectores",
  activating_please_wait: "Ativando usuário, por favor aguarde",
  user_could_not_be_activated: "Usuário não foi ativado",
  alarm_configuration: "Configuração de alarme",
  limit: "Limite",
  acknowledgement_enabled: "Habilita reconhecimento?",
  yes: "Sim",
  no: "Não",
  enabled: "Habilitado | Habilitados",
  disabled: "Desabilitado | Desabilitados",
  map: 'Mapa',
  connected_devices: "Dispositivos Conectados",
  for: "para",
  filter: "Filtro | Filtros",
  device: "Dispositivo | Dispositivos",
  connector: "Conector | Conectores",
  connector_status: "Estado do Conector",
  device_status: "Estado do Dispositivo",
  address: "Endereço",
  address_example: "Av. Paulista, 1000 - São Paulo - SP",
  type_the_full_address_and_click_search_button: "Informe o endereço completo e pressione o botão de pesquisa",
  neighborhood: "Bairro",
  coordinates: "Coordenadas",
  country: "Pais",
  protocol: "Protocolo",
  datetime_update: "Atualizar data/hora",
  optimized: "Otimizado",
  ip_network_configuration: "Configurações de rede IP",
  copy: "Copiar {item}",
  paste: "Colar",
  cut: "Recortar",
  timezone: "Fuso horário",
  location: "Localização",
  location_placeholder: "Warehouse 7",
  location_name: 'Localização',
  location_address_city: 'Endereço: Cidade',
  location_address_state: 'Endereço: Estado',
  location_address_latlng: 'Endereço: Coordenadas',
  location_address_number: 'Endereço: Número',
  location_address_street: 'Endereço: Rua',
  location_address_country: 'Endereço: País',
  location_address_zipcode: 'Endereço: CEP',
  location_address_district: 'Endereço: Bairro',
  location_address_extrainfo: 'Endereço: Complemento',
  is_connected: 'Está conectado?',
  number_active_alarms: 'Número de alarmes ativos',
  has_active_alarms: "Possui alarmes ativos?",
  type: "Tipo",
  connector_model: "Modelo do conector",
  duplication: "Duplicação",
  new_connector: "Novo Conector",
  new_device: "Novo Dispositivo",
  new_data: "Novo Dado",
  new_alarm: "Novo Alarme",
  is_hi_device: "Dispositivo HI?",
  device_address: "Endereço do dispositivo",
  local_storage_enabled: "Armazenamento local habilitado",
  local_storage_initial_address: "Endereço inicial da base local",
  local_storage_version_id: "Versão",
  has_the_same_process_area_as_its: {
    connector: "Mesma area de processo do conector?",
    device: "Mesma area de processo do dispositivo?",
    data: "Mesma area de processo do dado?",
  },
  replace_it: "Trocar",
  loading: "Carregando",
  updating: "Atualizando",
  connector_list: "Lista de conectores",
  device_list: "Lista de dispositivos",
  data_list: "Lista de dados",
  alarm_list: "Lista de alarmes",
  screen_list: "Lista de telas",
  device_name: "Nome do dispositivo",
  create_standard_device: "Criar dispositivo padrão",
  data: "Dado | Dados",
  data_name: "Nome do dado",
  memory_type: "Tipo de memória",
  memory_address: "Endereço de memória",
  memory_size: "Tamanho da memória",
  value_source: "Origem do valor",
  identity_number: "Identificador",
  data_identification: "Identificador do dado",
  configurations: "Configurações",
  read_only: "Apenas leitura",
  history: "Histórico",
  format: "Formato",
  calculation: "Cálculo",
  none: "Nenhum | Nenhuma",
  parameter: "Parâmetro | Parâmetros",
  result: "Resultado",
  data_calculation_1: "A * Valor",
  data_calculation_2: "A * Valor + B",
  data_calculation_4: "(Valor & A) >> B",
  copying: "Copiando",
  deleting: "Deletando",
  requires_acknowledgement: "Exige reconhecimento",
  value_of: "Valor de",
  trigger_criteria: "Critério de disparo",
  condition: "Condição",
  trigger_condition: "Condição para disparo|Condições para disparo",
  triggered_action: "Ação disparada|Ações disparadas",
  new_trigger_condition: "Nova condição para disparo",
  alarm_name: "Nome do alarme",
  connector_name: "Nome do conector",
  connector_plural: "Conectores",
  device_plural: "Dispositivos",
  data_plural: "Dados",
  alarm_plural: "Alarmes",
  resource: "Recurso",
  resources: "Recursos",
  you_have_reached_your_plan_limit: "Voce atingiu o limite de seu plano para este recurso",
  limit_exceeded: "Limite excedido",
  generic_notification: "Notificações",
  users_to_be_notified_by_email: "Usuarios à notificar por e-mail",
  users_to_be_notified_by_telegram: "Usuarios à notificar por Telegram",
  create_new_token: "Criar um novo token",
  edit_token: "Editar token",
  configured_screen_file_not_found: "Arquivo de tela configurada não encontrado",
  attention: "Atenção",
  add_new_connector: "Adicionar conector",
  add_new_device: "Adicionar dispositivo",
  add_new_data: "Adicionar dado",
  add_new_alarm: "Adicionar alarme",
  text_list: "Lista de textos|Listas de textos",
  new_text_list: "Nova lista de texto",
  new_text_value: "Informe um texto para o valor",
  text: "Texto",
  set_as_default: "Definir como padrão",
  text_list_name: "Nome da lista de texto",
  close: "Fechar",
  closed: "Fechado",
  already_have_an_account: "Ja possui uma conta? Faça o login.",
  create_account: "Registrar novo usuário",
  please_fill_this_form_to_create_an_account: "Por favor, preencha o formulario para criar sua conta.",
  i_accept_the: "Eu aceito os",
  terms_of_use: "Termos de uso",
  privacy_policy: "Política de privacidade",
  signup_success_message: "Sua conta foi criada e um e-mail foi enviado.\nAntes de efetuar o login, você precisa validar seu cadastro, abrindo seu e-mail e ativando sua conta",
  activation_success_message: "Sua conta está ativa e você já pode acessar o sistema.",
  congratulation: "Parabéns!",
  unknown_error: "Erro desconhecido",
  sorry_we_could_not_perform_your_request: "Desculpe, não conseguimos processar seu pedido",
  an_error_has_occurred: "Ocorreu um erro",
  user_group: "Grupo de usuário",
  permissions: "Permissões",
  access_permissions: "Permissões de acesso",
  save_and_exit: "Salvar e sair",
  save_and_continue: "Salvar e continuar",
  save_and_add_new: "Salvar e adicionar novo",
  feature_not_available: "Funcionalidade não disponivel",
  resend_the_activation_code: "Não recebeu o codigo de ativação? Solicite um novo código.",
  please_fill_this_form_to_require_a_new_activation_code: "Por favor, preencha o formulário para solicitar um novo código de ativação",
  please_fill_this_form_to_require_password_reset: "Por favor, preencha o formulário para receber o link de acesso ao formulario de troca de senha",
  please_fill_this_form_to_reset_your_password: "Por favor, preencha o formulário para redefinir sua senha",
  please_fill_out_this_form_to_activate_your_account: "Por favor, preencha o formulário para ativar sua conta",
  activation_code_request_success: "Um novo codigo de ativação foi requisitado e um e-mail foi enviado.\nAntes de efetuar o login, você precisa validar seu cadastro, abrindo seu e-mail e ativando sua conta",
  password_reset_request_success: "Enviamos um e-mail com instruções detalhadas de como trocar sua senha.",
  password_reset_success: "Sua senha foi alterada com sucesso.\nVoce ja pode realizar o login.",
  done: "Concluído!",
  back: "Voltar",
  reset: "Restaurar",
  download: "Baixar",
  download_historical_records_of: "Baixar registros históricos de {what}",
  invalid_profile: "Perfil de usuário inválido",
  the_specified_user_does_not_have_a_valid_profile: "Este usuário usuário não possui um perfil válido",
  maximum_connectors: "Número de conectores",
  registered_connectors: "Conectores em uso",
  maximum_third_party_connectors: "Número de equipamentos de terceiros",
  registered_third_party_connectors: "Equipamentos de terceiros em uso",
  maximum_data: "Numero de dados",
  registered_data: "Dados em uso",
  maximum_alarms: "Número de alarmes",
  registered_alarms: "Alarmes em uso",
  maximum_histories: "Número de historicos armazenados",
  registered_histories: "Históricos em uso",
  allowed_remote_access: "Acesso remoto",
  allowed_telegram_integration: "Integração Telegram",
  allowed_api_rest: "Acesso via REST API",
  plan: "Plano",
  my_plan: "Meu plano",
  current_plan: "Plano Atual",
  current_montly_fee: "Mensalidade Atual",
  current_screen: "Tela atual",
  total_usage: "Utilização Total",
  simulated_plan: "Plano Simulado",
  payment_overview: "Pagamentos",
  upgrade: "Upgrade",
  amount_to_be_paid: "Valor à pagar",
  balance_amount: "Saldo do contrato",
  minimum_value: "Valor mínimo",
  base_value: "Valor Base",
  monthly_fee: "Taxa contrato vigente",
  upgrade_plan: "Atualizar Plano",
  third_party_connectors: "Conectores de terceiros",
  third_party_devices: "Dispositivos de terceiros",
  remote_access: "Acesso remoto via <small><a href='https://www.hitecnologia.com.br/produtos/software/conectividade/plug-telemetria-acesso-remoto-via-portal-de-telemetria/' target=_blank>Plug Telemetria</a></small>",
  telegram_integration: "Integração Telegram",
  api_rest: "Acesso via REST API",
  transaction_history: "Histórico de transações",
  contract_status: "Estado do contrato",
  date: "Data",
  time: "Horário",
  datetime: "Data e hora",
  yes_im_sure: "Sim, tenho certeza.",
  do_you_really_want_to_upgrade_your_current_plan: "Você confirma a atualização de seu plano?",
  do_you_really_want_to_downgrade_your_current_plan_to_free: "Ao regredir para o plano FREE, você perderá acesso a recursos exclusivos dos planos pagos. Estes registros serão removidos permanentemente, sem possibilidade de recuperação. Você confirma a atualização de seu plano mesmo assim?",
  do_you_want_to_update_your_plan: "Deseja atualizar seu plano?",
  plan_update: "Atualização de plano",
  plan_update_warning: "Apenas recursos que não alteram o plano são permitidos nesta tela",
  geolocation: "Localização Geográfica",
  completed: "Operação realizada com sucesso!",
  your_plan_was_updated: "Seu plano foi atualizado",
  your_plan_could_not_be_updated: "Seu plano não pode ser atualizado",
  current_contract: "Contrato Atual",
  contract: "Contrato",
  contract_name: "Nome do contrato",
  contract_name_hint: "Defina o nome do contrato para encontrar o contrato na lista do menu.",
  contract_description: "Descrição do contrato",
  plan_value_fee: "Preço do plano",
  message: "Mensagem",
  system_event: "Eventos de Sistema",
  event: 'Evento',
  system_sub_event: "Sub-evento",
  contract_value: "Valor do contrato",
  transactions: "Transações",
  contract_resources: "Recursos do contrato",
  or: "ou",
  standard_device: "Dispositivo Padrão",
  manual: "Manual",
  column: "Coluna",
  columns: "Colunas",
  not_defined: "Não definida",
  customize_data_view: "Customizar visualização do painel de dados",
  customize_history_view: "Customizar visualização do painel historico",
  ordernation: "Ordenação",
  ordination_help_text: "Clique nas colunas ou arraste e solte os dados para definir uma ordenação. Clique em &nbsp;<i class='glyphicon glyphicon-eye-open'></i>&nbsp; para incluir/remover um dado da visualização final.",
  ascendent: "Ascendente",
  descendent: "Descendente",
  interval: "Intervalo|Intervalos",
  current_value: "Valor atual",
  hide_show: "Esconder/Exibir",
  sort: "Ordernar",
  sort_by: "ordenar por",
  then_by: "então por",
  sort_columns: "Ordenar colunas",
  redefine: "Redefinir",
  port: "Porta",
  plug_port: "Porta Plug",
  area_zoom: 'Zoom de área',
  hints: {
    inherits_parent_process_area: {
      connector: "Habilite&nbsp;caso&nbsp;este&nbsp;dispositivo&nbsp;pertença&nbsp;a mesma área de processo de seu conector",
      device: "Habilite&nbsp;caso&nbsp;este&nbsp;dado&nbsp;pertença&nbsp;a&nbsp;mesma área de processo de seu dispositivo",
      data: "Habilite&nbsp;caso&nbsp;este&nbsp;alarme&nbsp;pertença&nbsp;a&nbsp;mesma área de processo de seu dado",
    },
    notification: "Insira a lista para notificação por {group} (use [enter] para um novo item)",
    notification_enabled: "Uma vez habilitado, este recurso poderá enviar notificações",
    member_email: 'Insira o e-mail de um usuário já cadastrado ou envie um convite, para adicioná-lo como membro de seu contrato.',
    member_privileges: 'Defina os privilégios que este usuário terá em cada "Área de processo"',
    member_process_area: 'Área de processo a qual ele terá acesso',
    member_groups: 'Grupos de permissões que o usuário possuirá na Área de Processo selecionada',
    new_group: 'Crie um grupo para gerênciar as permissões de membros de seu contrato',
    group_permissions: 'Defina as permissões a serem concedidas aos membros deste grupo',
    process_area: 'Áreas de processo melhoraram o gerenciamento dos recursos cadastrados possibilitando a separação destes em contextos diferentes',
    maximum_number_of_devices_that_can_connect_to_the_portal: "Quantidade de dispositivos que poderão se conectar ao portal",
    maximum_number_of_third_party_devices_that_can_connect_to_the_portal: "Quantidade de dispositivos de terceiros (não HI) que poderão se conectar ao portal",
    maximum_number_of_data_that_can_be_stored_by_the_portal: "Quantidade máxima de Dados para o plano selecionado.\nUm Dado representa o valor coletado (lido) pelo Portal de uma variável de um Dispositivo (equipamento) remoto. Então aqui, considere a quantidade máxima de variáveis que serão adquiridas pelo Portal considerando todos os Dispositivos(equipamentos) que deseja acessar por este contrato.",
    total_number_of_alarms_that_can_be_configured_on_the_portal: "Número total de alarmes que poderão ser configurados no portal",
    reserved_number_of_data_with_history: "Quantidade permitida de dados com valores historiados. Por padrão apenas o ultimo valor registrado é acessivel",
    enables_disconnection_and_alarm_notifications_by_telegram: "Habilita o envio de notificações de desconexão e alarme via Telegram",
    enables_access_to_the_rest_api_for_developers_to_create_their_own_solution_based_on_portal_data: "Habilita o acesso a REST API para que desenvolvedores criem sua propria solução baseada nos dados do portal",
    enables_data_value_customization_with_text_assignment: "Habilita customização dos valores dos dados com a atribuição de texto à valores",
    enable_connector_geolocation_map_view: "Habilita geo-localização de conectores (visualização em mapa)",
    enables_remote_access: "Habilita acesso remoto. Necessário para depuração e configuração remota. Requer <a href='https://www.hitecnologia.com.br/produtos/software/conectividade/plug-telemetria-acesso-remoto-via-portal-de-telemetria/' target=_blank>Plug Telemetria</a>",
    enables_connector_models: "Um conector modelo facilita o gerenciamento de equipamentos similares, centralizando a configuração",
    screen_selection: "Selecione uma configuração de painéis para visualizar este equipamento",
    pooling_interval: "Selecione o intervalo de coleta por essa interface",
    acquisition_interval: "Selecione a taxa de aquisição de dados no conector",
    enables_custom_screens: "Habilita customização de telas",
    select_the_option_based_on_the_item_description: "Selecione a opção de acordo com a descrição do item",
    user_timezone: "Fuso horário para visualização de informações de data/hora na interface",
    date_time_format: "Configure como a data e hora serão exibidas",
    numeric_format: "Configure como o número será exibido",
    access_to_configuration_form: "Acesso ao formulário de configuração",
    select_the_event_that_will_trigger_actions: "Selecione o evento que irá disparar ações",
    select_the_type_and_actions_you_want_to_trigger_at_this_event: "Selecione o tipo e a ação que deseja adicionar à este evento",
    copy_and_open_anonymous: "URL da página copiada!<br>Pressione {key} para abrir o modo anônimo,<br>e Ctrl+V para testar a página",
    set_as_default: "Definir como padrão",
    aquisition_interval: "Define o intervalo válido para aquisição das amostras. Amostras fora do intervalo são descartadas",
    aquisition_interval_local_data: "Define o intervalo válido para o valor do dado.",
    synoptic_proportion: "Define os valores para cálculo da razão de proporção (aspect-ratio) entre largura e altura do painel. Caso haja uma imagem de fundo, use valores equivalentes à sua dimensão",
    connector_list_acl: "Restringe o acesso aos conectores marcados na lista. Caso não esteja marcado nenhum conector, o token terá acesso a todos os conectores do contrato",
    device_table_list_standard_panel: "Tornando-o padrão, a configuração do painel da pagina inicial será sobrescrita por essa. Você pode reverter essa definição..",
    mqtt_topic: "Nome do topico MQTT para este recurso.",
    mqtt_device_topic: "Nome do tópico MQTT para este dispositivo. Os tópicos dos dados ficarão vinculados à hierarquia apresentada.",
    mqtt_qos: "Qualidade do serviço para este recurso",
    mqtt_retain: "Clientes de topicos com esta opção habilitada recebem mensagem ao sobrescrever",
    screen_data_mapping: "Atribua novos dados à controles customizados ou painéis da tela que será aberta",
    history_type: "Define o modo como as amostras coletadas para o Dado serão registradas no Histórico de Dados do Conector.",
    identifier: "Identificador único para o recurso",
    click_to_open_ctrl_click_to_popup_it: "Clique para expandir ou Ctrl+Clique para abrir em janela popup",
    connector_data_mapping: "Possibilita que, no evento de alteração dos valores de dados deste conector, os mesmos sejam escritos em dados de outros conectores, conforme definido no mapeamento",
    data_mapping: "Habilita a permissão de compartilhamento do valor deste dado com outro conector.",
    access_mode: "Selecione a permissão de acesso deste dado",
    data_identification: "Número de Identificação do Dado na base de coleta local",
    default_interval: "Periodo inicial pré-definido para filtro dos valores amostrados",
    edges_options: {
      leave_them_empty: "Início e termino das amostras que não coincidem com o periodo selecionado não serão ajustados.",
      fit_in_time_window: "Ajusta amostras que não coincidem com o periodo selecionado para dentro do intervalo selecionado"
    },
    cell_interpolation: {
      leave_them_empty: "Apresenta célula vazia se não houver amostra no horário indicado pela linha",
      last_value: "Apresenta o último valor encontrado se não houver amostra no horário indicado pela linha",
      linear_interpolation: "Apresenta o resultado da interpolação linear nas linhas sem amostras no ho rário indicado"
    },
    background_image_url: "URL da imagem de fundo.",
    background_image_url_error: "URL não corresponde à do controle imagem de fundo (background)",
    realtime_chart: "Apresenta as variações dos valores em tempo real (a partir da abertura da tela). Essa opção não considera o período informado.",
    chart_zoom: "Habilita/desabilita funcionalidade de zoom do gráfico através de painel específico ou manipulação direta na área plotada",
    expression_for_actions: "Informe a expressão lógica que quando verdadeira disparará a sequência de ações",
    original_panel_dimension: "Dimensão configurada para o painel. Na prática, os valores informados definem a razão de proporção (aspect-ratio) entre a largura e a altura do painel. O valor da relação é usado para adaptar a dimensão inicial à área destinada ao painel no contexto do dispositivo",
    current_panel_dimension: "Dimensão atual do painel de acordo com a resolução deste dispositivo e dimensão do navegador",
    background_image: "Imagem de fundo deste painel. Para melhores resultados, mantenha a proporção em relação a dimensão do painel",
    background_image_locked: "Dimensão igual ao painel",
    background_image_unlocked: "Dimensão da imagem é independente do painel. Altere diretamente no controle.",
    canvas_size_locked: "Dimensão proporcional. Altura e largura mutuamente dependentes",
    canvas_size_unlocked: "Altura e Largura independentes",
    tags: "Tags (ou hashtags) são palavras-chaves que auxiliam na organização de seus ativos (itens/arquivos/conectores/telas, etc) para pesquisa e indexação.",
    integrated_refresh: "Valores atualizados por recargas manuais",
    update_at_startup: "Habilitando essa opção, o filtro de período será aplicado aos painéis (compatíveis) que requeiram histórico de dados. Do contrário, o filtro deverá ser manualmente aplicado.",
    history_disabled: "<b>Atenção!</b> Você desmarcou a opção de registro histórico para esse item. Valores já registrados ficarão inacessíveis",
    select_a_screen_connector: "A tela usará o conector corrente ou o selecionado abaixo para carga de seus recursos (dispositivos, dados e alarmes).",
    screen_parameters: "Informe o conjunto de parametros (chave e valor) utilizados pela tela que será aberta",
    mobile_native_printout: "Em dispositivos móveis, o sistema não converterá essa página em PDF, mas utilizará o proprio navegador para fazê-lo.",
    remove_panel: "Remove esse panel.\nMantenha Ctrl pressionado para recalcular o layout",
    data_collector_device: "Selecione qual o dispositivo responsável pela coleta de dados",
    device_address: "Endereço inicial do dispositivo",
    keep_screen_references_among_devices: "Habilitando esta opção você vinculará o novo dispositivo ao original ({item}).<br>Alterações no dispositivo original serão propagadas às suas cópias.",
    current_screen: "Tela atual do conector selecionado.<br>Se aplicável, clique no botão <i class='fa fa-exchange'/> para troca-la pela que está sendo editada.",
    resource_path: "Referência usada em componentes da tela que identificam este recurso",
    use_in_expression: "Clique para criar uma expressão JS com o dado selecionado e usá-la neste controle.\n{item}",
    expression_warning: "O valor dependerá do resultado da expressão definida no controle",
    add_device_as_screen_parameter: "Adicionar dispositivo como parametro para essa tela",
    inherit_template_settings: "Herdar de um modelo, fara com que este {item} assim como seus recursos sejam automaticamente atualizados quando o modelo for alterado",
    device_selection: "Lista de Dispositivos do Conector de Modelo disponíveis para essa instância. Apenas dados dos dispositivos selecionados consumirão recursos de seu plano.",
    memory_size: "Memória alocada para o tipo selecionado.<br>Deverá corresponder a reservada pelo programa do PLC (se aplicável).<br>Intervalos válidos: entre {min} e {max}",
    vector_size: "Número de posições reservadas do tipo selecionado.<br>Deverá corresponder a reservada pelo programa do PLC (se aplicável).<br>Intervalos válidos: entre {min} e {max}",
    local_data: "Um Dado Local de um Dispositivo existe apenas no contexto do Portal de Telemetria, ou seja, não será sincronizado com o Equipamento Remoto. Pode ser utilizado, por exemplo, para caracterizar um Dispositivo de maneira independente nas interfaces do Sistema.",
    fit_to_page: "Em modo de visualização, este painel tentará preencher a página dinamicamente",
    notification_mode: "Define quando a notificação deste alarme ocorrerá.",
    data_value_index: "Indice de valor é requerido para dado com mais de uma posição alocada de memoria",
    sample_loss_warning: "As alterações poderão acarretar em perda de valores amostrados",
    save_on_tab: "Se habilitado, a tecla TAB salvará o valor alterado",
    quick_preview: "Se habilitado, a interface mostrará as alterações nos controles que dependam deste dado, mesmo que a escrita ainda não tenha ocorrido",
    disabling_resource: "Desabilitar recurso",
    data_to_enable_history: "Defina um dado que sinalizará se o armazenamento histórico de valores deverá ser habilitado.\
    \nAmostras históricas serão armazenadas apenas se o valor do dado controlador for o especificado.\n\
    Dados controlados precisam ter a opção HISTÓRICO habilitada",
    overwrite_existing_file: "O arquivo  \"{file}\"  já existe em  \"{folder}\" ",
    current_value_local_data: "Valor de um Dado Local",
    data_filter: "Permite filtrar as amostras com base no valor de um dado pertencente ao Conjunto de Dados configurados nos paineis Tabela/Gráfico\n O filtro irá considerar apenas amostras registradas no mesmo segundo das do dado ultilizado como filtro",
    dropdown: "Clique para marcar/desmarcar as opções<br/>Ctrl+Clique marca a opção sob cursor.<br/>Pressione OK para aplicar as alterações.",
    machine_name: "Nome da máquina",
    machine_unit: "Unidade de medida para a produtividade da máquina",
    machine_default_hourly_production: "Define a velocidade padrão de produção da máquina na qual será utilizada para os cálculos dos índices relacionados ao OEE. Exemplo de valor: 100 (peças/hora)",
    machine_production_order_filtering: "Filtrar por Ordem de Produção",
    machine_production_order_exists: "Marque esta opção caso a Máquina utilize o conceito de Ordem de Produção na sua operação para agrupar e identificar as informações.",
    machine_casualty_limit: "Limite do índice de OEE",
    machine_unavailability_limit: "Indica o limite percentual aceitável para a Disponibilidade da Máquina",
    machine_low_quality_limit: "Indica o limite percentual aceitável para a Qualidade de produção da Máquina",
    machine_low_performance_limit: "Indica o limite percentual aceitável para a Performance de produção da Máquina",
    machine_url_button: "Caso configurado, será apresentado um botão no Dashboard da Máquina no  OnlinEE que levará para a URL configurada abaixo, como por exemplo, para  o Dashboard do Conector associado a Máquina.",
    machine_url: "Link (URL) a ser aberto quando clicar no botão que ficará disponível no OnlinEE",
    machine_url_button_text: "Texto a ser apresentado no botão que ficará disponível no OnlinEE",
    machine_limits: "Define um valor (percentual) de limite para as informações abaixo de maneira que seja indicado no Dashboard da Máquina no OnlinEE se estes limites estão dentro do configurado (aceitável)",
    header: "Cabeçalho superior da página dashboard",
    sidebar: "Menu lateral esquerdo da página do dashboard",
    does_it_show_models_only_or_all_connectors: "Mostrar apenas modelos ou todos os conectores?",
    force_end_test: "Encerra o período de testes deste contrato e inicia a cobrança pelo serviço",
    use_dashboard_url: "Preencher este campo com o caminho de URL que abre a tela configurada no Conector",
    alarm_limit_float: "Valor de limite para acionamento do alarme. Preencher este campo com um valor numérico com casas decimais. Utilize somente números e o separador deve ser o ponto e não a vírgula.",
    alarm_limit_number: "Valor de limite para acionamento do alarme. Preencher este campo com um valor numérico sem casas decimais. Utilize somente números.",
    alarm_limit_string: "Valor de limite para acionamento do alarme. Preencher este campo com um texto.",
    open_machine_dashboard: "Abrir Dashboard da Máquina no OnlinEE",
    button_export: "Exportar registros para um arquivo CSV. Você pode selecionar individualmente apenas os registros que deseja exportar ou não selecionar nenhum para exportar todos.",
    button_import: "Importar registros de um arquivo CSV.",
    button_download_backup: "Criar um backup de registros em um arquivo único.",
    reason_list_value: "Identificação numérica a ser informada pelo usuário no processo para associar os eventos de parada a este motivo.",
    reason_list_text: "Identificação textual do motivo de parada que será apresentado no OnlinEE(Dashboard, relatórios, etc)",
    reason_list_color: "Cor associada ao motivo de parada que será apresentado no OnlinEE.",
    reason_list_is_planned_stop: "Indica se a parada é programada (planejada). O tempo de parada de uma máquina quando é programada não é contabilizado no cálculo do índice de OEE.",
    casualty_list_value: "Identificação numérica a ser informada pelo usuário no processo para associar os eventos de perda a este motivo.",
    casualty_list_text: "Identificação textual do motivo de perda que será apresentado no OnlinEE(Dashboard, relatórios, etc)",
    casualty_list_color: "Cor associada ao motivo de perda que será apresentado no OnlinEE.",
    identity_embedded_app: "Preencha aqui a identificação associada a este Dado na aplicação/firmware do equipamento remoto.",
    linked_panel_selector: "Usar Painéis de outra Tela como Base",
    editor: {
      "screen": "Definir propriedades do Dashboard e Conector de Referência",
      "layout": "Definir layouts e painéis para o Dashboard",
      "restore": "Restaurar a última versão publicada do Dashboard",
      "preview": "Pré-visualizar como este Dashboard ficaria após publicação.",
      "publish": "Salvar nova versão do Dashboard."
    },
    remove_all: "Remover todos|Remover todas",
    showing_n_of_n_items: "Exibindo {items} de {total} itens",
    just_one_may_be_placed: "Apenas um poderá ser alocado",
    data_history_rate: "Frequência de armazenamento de novos registros no Histórico (caso o Conector esteja online com o Portal)",
    notes_insert_datetime: "Insere uma nova linha com a data e hora atual no final da anotação",
    time_to_notity_disconnection: "Informe o tempo em que o Conector deve permanecer desconectado do Portal (offline) para que a notificação de desconexão seja enviada (caso configurada).",
    reference_connector: "Define a base de dados padrão para essa tela. Utilize a função de <b>Substituição de Dados</b> na seção de <b>Manutenção</b> para reconfigurar sua Tela caso altere este campo(após a publicação da Tela).",
    data_replacement: "Essa operação substituirá todas as ocorrências do dado <b>Origem</b> para o dado <b>Destino</b> deste Tela, incluíndo controles, paineis e ações.",
    is_hi_device: "Equipmento produzido ou comercializado com firmware desenvolvido pela HI Tecnologia?",
    cleaning_due_date: "Itens presentes por mais de {days} dias na lixeira serão excluídos automaticamente.",
    show_disconnection: "Apresenta no gráfico periodos que houveram desconexões.",
    disconnection_threshold: "Duração mínima da desconexão. Abaixo deste limite, não será considerado como desconexão",
    disconnection_color: "Cor da área que representará o período desconectado",
    disconnection_data_line: "Mostrar a tendência da evolução dos dados durante o periodo desconectado?",
    public_access_links: "Links de acesso público a telas (dashboards) deste Conector",
    public_access_links_screen: "Tela (dashboard) que será apresentada quando acessado o link",
    public_access_links_url: "Após expirada a duração do acesso, o usuário será redirecionado para o link configurado aqui. Caso não configurado, será apresentada a página de login no Portal",
    public_access_links_timeout: "Duração(em minutos) que o usuário poderá visualizar/interagir com a tela do Conector antes de ser feito um logout/redirecionamento",
    public_access_links_expires_at: "Define uma data/hora de limite no qual o link de acesso público funcionará. Após a validade, o acesso será bloqueado.",
    public_access_links_description: "Define uma descrição para identificar o objetivo deste link.",
    remote_access_token: "Tokens de acesso remoto ao Conector",
    remote_access_token_token: "Identificador do Token a ser cadastrado no PLUG TELEMETRIA(software que disponibiliza canais de acesso remoto ao Conector)",
    remote_access_token_type: "Define modo de aplicação da token",
    remote_access_token_type_software: "Token utilizado em conjunto com o software PLUG TELEMETRIA para viabilizar um canal de comunicação remoto entre o equipamento conectado ao Portal e um Software(o HIstudio por exemplo)",
    remote_access_token_type_equipamento: "Token utilizado em conjunto com o software PLUG TELEMETRIA para viabilizar um canal de comunicação entre o Conector e um equipamento remoto.",
    remote_access_token_expires_at: "Define uma data/hora de limite no qual o token de acesso remoto poderá ser utilizado. Após a validade, não serão permitidas novas conexões.",
    remote_access_token_description: "Define uma descrição para identificar o objetivo desta token.",
    show_in_iframe: "Visivel quando renderizado em iframe (painel embutido em outra página)",
    disabled_resources: "Itens desabilitados serão ignorados pelo monitoramento e coleta de suas informações",
    sync_enabled: "Quando habilitado, versões futuras do painel base serão sincronizadas com este painel (local nesta tela).",
    filter_required: "Quando habilitado (filtro obrigatório), os resultados só serão apresentados se houver correspondência com os critérios selecionados.<br/>Se desmarcado, o resultado completo será exibido quando não houver nenhum critério selecionado.",
    chart_tooltip: "Tooltip apresenta informações dos valores plotados nas coordenadas sob o ponteiro do mouse.<br/>Sua customização permitirá editar o posicionamento e opções relacionadas à fonte",
    chart_word_wrap: "Quando habilitado apresenta um dado por linha. Desabilitado, o painel tentará apresentar os dados na mesma linha. Ajuste a altura das linhas",
    chart_tooltip_position: "Quando desabilitado apresentará o painel tooltip sob o ponteiro do mouse. Se habilitado você poderá editar a posição do painel. Deixe vazio (campos x ou y) para usar o padrão da coordenada",
    font: "Configurações relacionadas a ornamentação do texto",
    keep_visible: "Uma vez aberto, preservará o painel visivel na tela, mesmo após mover o ponteiro do mouse para fora do gráfico",
    show_at_startup: "Quando habilitado, apresentará as informações assim que estiverem disponiveis",
    destination_connector: "Selecione o conector com os dados que irão substituir os dados originais",
    original_screen_contract: "Contrato original da tela",
    history_tolerance_value: "Define o valor mínimo de variação que o valor do Dado deve atingir para ser registrado no histórico. Quando vazio, registra qualquer variação.",
    screen_name_already_exists: "Já existe uma Tela com este Nome.",
    download_screen_json: "Exporta somente o arquivo JSON de definição da Tela sem as imagens associadas.",
    download_screen_zip: "Exporta um único arquivo (extensão HPS) contendo a configuração da Tela e todas as imagens utilizadas.",
  },
  placeholders: {
    tags: "Exemplo: projeto1 cliente",
    search: "Ex: nome-conector, #marcador1, etc"
  },
  titles: {
    reorder_table: "Clique para reordenar a tabela",
    parent_selection_locked: "Desbloquear alteração de ancestralidade",
    parent_selection_unlocked: "Bloquear alteração de ancestralidade",
    select_data: "Selecionar dado",
    select_all_data: "Selecionar todos os dados",
    add_all_data: "Adicionar Todos",
    add_selected: "Adicionar Selecionado",
    tab_selection: "Visualizar dados do dispositivo",
    tab_editor: "Escrita de valor no dado",
    data_not_allows_writing: "Este dado não permite escrita",
    data_allows_writing: "Este dado permite escrita",
    reset_value: "Restaurar valor anterior",
    insert_data_value: "Insira o valor do dado",
    change_data_value: "Alterar valor",
    access_alarms_history: 'Acessar Histórico de Alarmes',
    insert_search_text: 'Insira o texto para pesquisa',
    pending_writing: 'Escrita de valor pendente',
    insert_date_interval: 'Insira um intervalo',
    select_date_interval: 'Selecione um intervalo',
    filter_by_data: 'Filtrar por dado',
    filter_by: 'Filtrar por',
    data_filter: "Filtro de dados",
    view_table: 'Visualizar tabela',
    view_graph: 'Visualizar gráfico',
    view_history: 'Visualizar histórico',
    view_config: 'Visualizar configuração',
    previous_page: 'Página anterior',
    first_page: 'Primeira página',
    last_page: 'Última página',
    next: "Próxima|Próximo",
    next_page: 'Próxima página',
    load_next_page: 'Carregar próxima página',
    you_dont_have_permission: 'Você não tem permissão para editar',
    remove_process_area: 'Remover Área de Processo',
    select: 'Selecionar',
    select_all: 'Selecionar todas | Selecionar todos',
    clear_selection: 'Limpar seleção',
    cancel_selection: 'Cancelar seleção',
    group_name: 'Nome do grupo',
    group_description: 'Descrição do grupo',
    group_is_system: 'Grupo de sistema não pode ser alterado',
    access_dashboard: 'Acessar dashboard do equipamento',
    showing_search_results: 'Mostrando resultados de pesquisa. Remova o filtro para exibir demais itens.',
    panel_access_by_process_area: 'Apenas usuários com acesso a uma das Áreas de Processo abaixo verão este painel',
    button_access_by_process_area: 'Apenas usuários com acesso a uma das Áreas de Processo abaixo verão este botão',
    button_interaction_by_process_area: 'Apenas usuários com acesso a uma das Áreas de Processo abaixo poderão interagir com este botão',
    control_access_by_process_area: 'Apenas usuários com acesso a uma das Áreas de Prcesso abaixo verão este controle',
    control_interaction_by_process_area: 'Apenas usuários com acesso a uma das Áreas de Prcesso abaixo poderão interagir com este controle',
    access_mode: "Modo Leitura / Escrita",
    layout: {
      large_panel: "Painel largo",
      two_panels: "Dois painéis alinhados",
      three_inline_panels: "Três painéis alinhados",
      large_top_panel_two_panels: "Painel largo e dois painéis",
      two_panels_large_bottom_panel: "Dois painéis alinhados e painel largo",
      long_left_panel_two_panel: "Painel esq alto e dois dois painéis",
      two_panels_long_right_panels: "Dois painéis e painel dir alto",
      four_panels: "Quatro painéis",
    },
    calendar: "Calendário",
    tabs: "Tabs",
    filters: "Filtros",
    download: "Download",
    download_as_CSV: "Arquivo CSV",
    download_as_XLS: "Arquivo XLS",
    download_up_to_365_days: "Baixar até 365 dias",
    default_dashboard_for_user: "Aba exibida por padrão ao acessar a Home do Portal (apenas para este usuário).",
    default_dashboard_for_contract: "Aba exibida por padrão ao acessar a Home do Portal (válida para todos os usuários do contrato que não possuem outra preferência).",
    resource_chart_hint: 'Relação de Utilização/Limite. O gráfico mostra a porcentagem utilizada ou prevista de utilização.',
    select_process_areas_for_all_users: 'Selecione Áreas de Processo para associar a todos os usuários do grupo de uma só vez',
    select_groups_for_all_users: 'Selecione Grupos de Permissões para associar a todos os usuários da área de processo de uma só vez',
    add_process_areas_to_users: 'Adicione Áreas de Processo para todos os usuários',
    add_groups_to_users: 'Adicione Grupos de Permissões para todos os usuários',
    print: "Impressão",
    preview: "Pre-visualização",
    set_screen_as_default: "Definir esta tela como a padrão para o conector?",
    cell_with_data_reference: "Célula com referencia à dado|Células com referências à dados",
    there_are_no_named_data_controls: "Não existem controles nomeados",
    there_are_no_data_cell: "Não existem células com dados",
    gallery_panels: "Painéis da galeria",
    gallery_layouts: "Layouts da galeria",
    base_screen: "Tela base",
    screen_panels: "Painéis da tela selecionada",
    base_screen_panels: "Painéis da tela base",
    screen_layouts: "Layouts da tela selecionada",
    remote_panel: "Painel Base",
    sync_enabled: "Habilita Sincronização",
    source_screen: "Tela de origem",
    data_collector_device: "Dispositivo coletor",
    consume_forecast: "Consumo: {devices} dispositivos e {data} dados de sua cota",
    resource_path: "Referência do recurso",
    available_list: "{list} disponíveis",
    number_of: "Qtde de {list}",
    expression_created: "Expressão criada",
    data_consumption: "Consumo de dados",
    forecast_data_consumption: "Previsão de consumo de dados",
    invitation_info: "Caso não exista, será criado um membro inativo em seu contrato. Você poderá pre-definir seus privilégios",
    add_your_first: "Cadastre seu primeiro {item}",
    local_data: "Dado local",
    current_value_local_data: "Valor",
    fit_to_page: "Preencher página",
    form: "Formulário",
    data_value_index: "Indice do valor",
    index: "Índice",
    vector_size: "Tamanho do vetor",
    element: "Element|Elements",
    quick_preview: "Visualização rápida",
    save_on_tab: "Tecla TAB salva",
    data_to_enable_history: "Ativador de histórico",
    overwrite_existing_file: "Sobrescrever o arquivo salvo?",
    value_at: "Valor na posição ",
    notification_mode: 'Modo de notificação',
    components: "Componentes",
    visible_components: "Componentes visíveis",
    delay_before_persist: "Atraso antes de salvar",
    machine_name: "Nome da máquina",
    machine_unit: "Unidade",
    machine_default_hourly_production: "Produção/hora Padrão",
    machine_production_order_filtering: "Filtrar por Ordem de Produção",
    machine_production_order_exists: "Utiliza Ordem de Produção?",
    machine_casualty_limit: "OEE",
    machine_unavailability_limit: "Disponibilidade",
    machine_low_quality_limit: "Qualidade",
    machine_low_performance_limit: "Performance",
    machine_title_url_button: "Botão para URL",
    machine_url: "Endereço URL",
    machine_url_button_text: "Texto do Botão",
    header: "Cabeçalho",
    sidebar: "Menu lateral",
    editing_not_allowed: "Edição não permitida",
    force_end_test: "Encerrar período de teste e contratar",
    use_dashboard_url: "Usar tela do Conector",
    identity_embedded_app: "Identificação na Aplicação",
    trash_can: "Lixeira",
    dynamic_data_exchange: "Troca dinâmica de dados",
    remote_connector: "Conector remoto",
    show_disconnection: "Mostrar Desconexão",
    trend: "Tendência",
    any: "Qualquer",
    disconnection_threshold: "Limite de duração da desconexão",
    data_history_rate: "Taxa de Histórico de Dados",
    history_rate: "Taxa de Histórico",
    time_to_notity_disconnection: "Tempo OFFLINE para notificar desconexão",
    notes_insert_datetime: "Data/hora",
    tooltip: "Tooltip",
    keep_visible: "Manter aberto",
    try_it: "Testar",
    inverse: "Invertido|Invertida",
    history_tolerance_value: "Valor mínimo de variação",
    screen_export_modal: "Opções",
    download_screen_json: "Arquivo de definição",
    download_screen_zip: "Exportar Tela Completa",
    overwrite_n_items: "Sobrescrever {count} item(s)?",
    item_already_exists: "Item {name} já existe",
    named_query: "Consulta nomeada|Consultas nomeadas",
    group_by_expression: "Expressão agrupamento",
    aggregation_function: "Função de agregação",
    column_name: "Nome da coluna"
  },
  keep_me_connected: "Manter-me conectado",
  details: "Detalhes",
  page: {
    sign_in: "Login",
    sign_in_page: "Página de login",
    sign_up: "Registro",
    password_reset: "Redefinir senha",
    account_activation: "Ativação de conta",
    user_dashboard: "Painel do usuário",
    connectors: "Conectores",
    devices: "Dispositivos",
    data: "Dados",
    alarms: "Alarmes",
    control_access: "Controle de acesso",
    user_profile: "Perfil do usuário",
    user_plan: "Plano do usuário",
    event_history: "Log de eventos",
    equipment_dashboard: "Painel do equipamento",
    data_value_editor: "Editor de dados",
    alarm_notification_editor: "Edição das notificações de alarme",
    dashboard_editor: 'Editor de telas',
    custom: 'Aba customizada',
    settings: 'Configurações',
    screens: "Telas",
    changelog: "Notas de Versão",
    onlinee_config: "Configurar OnlinEE",
    history_usage_statistics: "Estatísticas de Uso do Histórico de Dados",
    terms_of_service: "Termos de Uso"
  },
  pooling_interval: "Intervalo de atualização",
  pooling_interval_ui: "Intervalo para a atualização das informações na interface",
  manual_pooling_interval: "Intervalo entre atualizações manuais",
  manual_pooling_interval_ui: "Intervalo mínimo permitido entre atualizações realizadas pelo usuário manualmente",
  acquisition_interval: "Intervalo de aquisição",
  define_this_connector_as_model: "Definir este conector como Modelo para outros conectores",
  inherit_template_settings: "Herdar configurações do modelo",
  hardware_model: "Modelo do hardware",
  connector_models: "Conectores modelo",
  models: "Modelos",
  connectors: "Conectores",
  model_based_connectors: "Conectores baseado em modelo",
  apply_changes_to_instances: "Aplicar alterações na(s) instância(s)",
  do_not_apply_changes_to_instances: "Não aplicar alterações nas instâncias",
  apply_changes_to_instances_alert: "Ao salvar o recurso com esta opção habilitada, todas as configurações do modelo serão aplicadas nos recursos associados",
  visible: "Visível",
  invisible: "Invisível",
  selected: "Selecionad{gender} | Selecionad{gender}s",
  n_item_selected: "{n} item selecionado|{n} itens selecionados",
  removal_model_message: "Ao remover este modelo, eventuais recursos que utilizem o mesmo perderão esta referencia",
  new_instances_limit_exceeded: "* Limite para novas instâncias excedido.",
  contract_state_alerts: {
    contract_requires_attention: "O contrato acessado requer atenção.",
    please_contact_admin: "Por favor, contate o administrador do contrato para mais informações",
    running_out_of_resources: "Atenção, o saldo associado ao seu contrato está chegando ao fim e poderá desabilitar algumas funcionalidades.",
    balance_is_not_enough: "Seu contrato foi desabilitado pois o pagamento não foi confirmado",
    test_ending: "Seu período de testes está expirando. Por favor, finalize a contratação para continuar utilizando nossa plataforma.",
    not_collecting_data: "Os dados não estão sendo coletados. Por favor, contate nosso departamento comercial."
  },
  screen: "Tela | Telas",
  panel: 'Painel | Painéis',
  top: 'Superior',
  middle: "Meio",
  bottom: 'Inferior',
  left: 'Esquerda',
  right: 'Direita',
  center: "Centro",
  width: 'Largura',
  height: 'Altura',
  image: 'Imagem',
  label: 'Rótulo',
  padding: "Espaçamento",
  url: 'URL',
  eg: 'ex. | Ex.',
  title: "Título",
  subtitle: "Sub Título",
  screen_editor: "Editor de telas",
  this_screen_cannot_be_overwritten: "Esta tela não pode ser sobrescrita. Prosseguindo, uma nova tela será criada.",
  publish: "Publicar",
  publish_new_version: "Publicar nova versão",
  last_version: "Última versão",
  dimensions: "Dimensões",
  edit_panel: "Editar este painel",
  edit_other_panels: "Editar este e outros painéis",
  new_line: "Nova linha",
  synoptic: {
    remove_image: 'Remover imagem',
    properties: 'Propriedades',
    controls: 'Controles',
    available_controls: "Controles disponiveis",
    insert_label: 'Insira um rótulo',
    data_value: 'Valor do dado',
    background_color: 'Cor de fundo',
    background_image: 'Imagem de fundo',
    text_color: 'Cor do texto ou frente',
    colors: "Cores",
    error_state: "Erro",
    focus_state: "Foco",
    pending_state: "Pendente",
    new_image: 'Nova imagem',
    new_text: "Novo texto",
    image_list: 'Lista de imagens',
    text_list: 'Lista de texto',
    data_name: 'Nome do dado',
    data_not_found: 'Dado(s) não encontrado(s)',
    reference_connector: 'Conector de referência',
    border: 'Borda',
    grid_line: "Grade",
    border_radius: 'Arredondar borda',
    solid: "Sólida",
    dashed: "Tracejada",
    dotted: "Pontilhada",
    double: "Dupla",
    groove: "Sulco",
    ridge: "Cume",
    inset: "Embutida",
    outset: "Saltada",
    none: "Nenhuma",
    text_align: 'Alinhamento',
    font: 'Fonte',
    select_from_library: 'Selecionar de biblioteca',
    select_image: 'Selecionar imagem',
    rotation: 'Rotação',
    lock: "Travar",
    unlock: "Destravar",
    panel_locked: 'Painel travado',
    enable_select_mode: 'Ativar movimentação restrita',
    disable_select_mode: 'Desativar movimentação restrita',
    panels: {
      basic_equipment_info: 'Informações básicas do conector',
      basic_equipment_alarm: 'Alarme do conector',
      maintenance: 'Manutenção',
      synoptic: 'Sinóptico',
      equipment_history: 'Histórico do conector',
      alarms: 'Alarmes',
      history_panel: "Painel histórico",
      blank_panel: "Painel vazio",
      devices: "Dispositivos",
      event_viewer: "Visualizador de eventos",
      notification: "Editor de notificações",
      equipment_runtime_data_editor: "Painel de dados",
      equipment_toolbar_panel: "Barra de ferramentas",
      history_chart_panel: "Gráfico histórico",
      chart_panel: "Painel gráfico",
      dashboard_table_panel: "Tabela",
      rich_text_panel: "Painel de texto",
      image_panel: "Painel de imagem",
      equipment_status_panel: "Panel de status do conector",
      custom_device_list: "Lista configuravel de dispositivos - tabular",
      equipment_card_panel: "Lista configuravel de connectores - cartões",
      equipment_search_panel: "Estatísticas e pesquisa de conectores/dispositivos",
      title: "Painéis",
      custom_panel: "Painel customizável via IFrame"
    },
    events: {
      on: "Ao",
      click: "Clique | Clicar",
      released: "Liberação | Liberar",
      change: "Alteração | Alterar",
      execute_action: "Executar ação|Executar ações",
      dataChange: "Alterar valor",
      select_an_event: "Selecione um evento",
      select_an_action: "Selecione uma ação",
      add_an_action: "Adicionar ação",
      add_an_event: "Adicionar evento",
      activated: "Ativo/Aberto",
      deactivated: "Inativo/Fechado",
      actions: {
        page: "Página",
        screen: "Tela",
        print: "Impressão",
        tag: "Dado",
        user: "Usuário",
        panel: "Painel",
        style: "Estilo",
        content: "Conteúdo",
        css: "CSS",
        full_screen: "Tela cheia",
        page_open: "Abrir página",
        page_tab: "Abrir página (guia)",
        page_window: "Abrir página (janela)",
        page_modal: "Abrir página (modal)",
        page_home: "Abrir página inicial",
        page_previous: "Voltar à página anterior",
        page_next: "Ir à próxima página",
        page_refresh: "Atualiza conteúdo da página",
        screen_open: "Abrir tela",
        screen_tab: "Abrir tela (guia)",
        screen_window: "Abrir tela (janela)",
        screen_modal: "Abrir tela (modal)",
        screen_popup: "Abrir tela (popup)",
        print_preview: "Pré visualização",
        native_print: "Impressão nativa",
        panel_open: "Abrir painel",
        panel_modal: "Abrir painel (modal)",
        panel_expand: "Expande painel",
        panel_full_screen: "Tela cheia",
        script_function: "Executar função JavaScript",
        tag_set_constant: "Atribuir valor ao dado",
        tag_invert: "Inverter valor do dado",
        set_next: "Atribui proximo valor ao dado",
        user_logout: "Efetuar logout",
        user_profile: "Abrir perfil do usuário",
        user_manage: "Abrir Controle de Acesso",
        color: "Cor",
        background_color: "Cor de fundo",
        hide: "Esconde",
        show: "Mostra",
        text: "Texto",
        image: "Imagem",
        disable: "Desabilita",
        form: "Form",
        submit: "Enviar",
        reset: "Restaurar",
        animation: "Animação",
        onlinee: "OnlinEE",
        onlinee_home: "Lista de Máquinas",
        onlinee_machine_dashboard: "Dashboard de Máquina",
      },
      process_area: 'Restringe a execução da ação a usuários com acesso à Área de Processo definida.'
    },
    controls: {
      SynopticStaticLabel: "Textos estáticos",
      SynopticFormattedDisplayValue: "Visualização de valores de dados",
      SynopticImage: "Representação de uma imagem",
      SynopticStatusLabel: "Lista de textos associados a valores de dados",
      SynopticStatusIcon: "Lista de imagens associados a valores de dados",
      SynopticDataValueInput: "Edição de valores de dados",
      SynopticDataValueSlider: "Representação e edição de valores de dados através de uma barra de medição",
      SynopticDataValueSelect: "Edição de valores de dados através de uma caixa de seleção",
      SynopticDataValueCheckbox: "Edição de valores de dados através de uma caixa de checagem",
      SynopticDataValueToggle: "Edição de valores de dados através de um botão de posição (ON e OFF)",
      SynopticButton: "Execução de ação",
      SynopticProgressBar: "Representação de valores de dados em uma barra de progresso (vertical ou horizontal)",
      SynopticSimpleTable: "Tabela configuravel",
      SynopticGauge: "Representação de valores de dados em um gauge",
      SynopticChart: "Representação de valores de dados em um gráfico",
      SynopticCustomControl: "Incorporação de pagina externa"
    },
    uses_page_default_color: 'Usa cor padrão da página',
    process_area: 'Restringe visualização do controle aos usuários com acesso à Área de Processo selecionada.',
  },
  custom_screens: "Telas customizadas",
  failed_to_load_panels: "Falha ao carregar painéis",
  failed_to_update_ref_map: "Falha ao atualizar conector de referência",
  select_ref_connector: 'Selecionar conector de referência',
  unsaved_changes: 'Mudanças não salvas',
  no_unsaved_changes: 'Sem mudanças para publicar',
  publish_changes: 'Publicar mudanças',
  release_notes: 'Notas de lançamento',
  release_notes_placeholder: "Descreva aqui o que há de novo nessa versão",
  this_screen_cannot_be_removed: 'Essa tela não pode ser removida',
  data_source: "Origem",
  content: "Conteúdo",
  global: "Global",
  control: "Controle",
  list: "Lista",
  local: "Local",
  this_will_reset_all_of_your_unpushed_changes: "Alterações não publicadas serão descartadas",
  upload: 'Enviar',
  uploading: 'Enviando',
  upload_image: 'Enviar imagem|Enviar multiplas imagens',
  library: 'Biblioteca',
  no_images: 'Sem imagens',
  select: 'Selecionar',
  undo_changes: 'Desfazer modificações',
  public: 'Público | Pública',
  create: 'Criar',
  rename: 'Renomear',
  creating: 'Criando',
  add: 'Adicionar',
  ref_map_required: 'Selecione o conector de referência para continuar.',
  publish_screen_before_edit: 'Publique a tela para editar este painel.',
  no_template: 'Arquivo de tela indisponível.',
  select_a_screen: 'Selecione uma tela',
  select_a_screen_to_edit: 'Selecione uma tela para editar',
  after_save_action: "Ação após escrita",
  ref_map_not_found: "Conector de referência não encontrado para esta tela. | Acesse o editor de telas para configurar um.",
  error: 'Erro',
  version: "Versão",
  versions: "Versões",
  restore: "Restaurar",
  restore_screen: 'Restaurar última versão publicada da tela',
  panel_properties: 'Propriedades do painel',
  panel_content_properties: "Propriedades específicas do painel",
  new_panel: 'Novo painel em branco',
  publish_screen: 'Publicar tela',
  close_editing: 'Sair do modo de edição',
  undo_action: 'Desfazer ação',
  redo_action: 'Refazer ação',
  view: "Visualizar",
  expand_collapse_panel: "Expandir/Colapsar Painel",
  undo: "Desfazer",
  redo: "Refazer",
  not_persisted: "Não persistente",
  test_value: "Valor de teste",
  equipment_runtime_data_editor: "Painel de dados",
  export: "Exportar",
  import: "Importar",
  import_from_contract: "Importar do contrato",
  limits: "Limites",
  minimum: "Mínimo",
  maximum: "Máximo",
  increment: "Incremento",
  undefined: "Indefinido",
  constant: "Constante",
  from_data: "De um dado",
  command_status: "Status do comando",
  control_status: "Status do controle",
  has_pending_commands: "possui comandos pendentes",
  command_sent: 'Comando enviado',
  data_value_updated: 'Valor do dado atualizado',
  top_left: "em cima esquerda",
  top_right: "em cima direita",
  top_center: "em cima centro",
  middle_left: "meio esquerda",
  middle_right: "meio direita",
  middle_center: "meio centro",
  bottom_left: "em baixo esquerda",
  bottom_right: "em baixo direita",
  bottom_center: "em baixo centro",
  remove_selected_ones: 'Remover selecionados',
  proportional: 'Proporcional',
  proportion: "Proporção|Proporções",
  unlinked: 'Não emparelhado',
  equal: 'Igual',
  review_the_screen_controls_associated_with_this_item: "Após salvar, revise os controles de tela associados a este item",
  screen_importation: "Importação de tela",
  destination: "Destino",
  action: "Ação",
  key: "Chave | Chaves",
  insert_key: "Insira uma chave",
  insert_value: "Insira um valor",
  appearance: "Aparência",
  events: "Eventos",
  controls: 'Controles',
  properties: 'Propriedades',
  available_controls: "Controles disponiveis",
  same_revision_code_error: "Versão já utilizada em última publicação",
  old_version_alert: "Você está editando a versão desatualizada desta tela {version}. Publicá-la poderá sobrescrever outras alterações!",
  download_failed: 'Falha ao fazer download',
  executed: "Executado",
  invalid_value: "Valor inválido",
  invalid_css_value: 'Valor inválido. Use "px". | Valor inválido. Use "px" ou "%".',
  authenticated: "Autenticada",
  sent: "Enviado",
  update_page: "Atualiza informações da página",
  show: 'Exibir',
  info: "Informações",
  hint: "Dica",
  h1: 'H1',
  h2: 'H2',
  h3: 'H3',
  h4: 'H4',
  h5: 'H5',
  h6: 'H6',
  rich_text: {
    bold: 'Negrito (Ctrl+B)',
    italic: 'Itálico (Ctrl+I)',
    underline: 'Sublinhado (Ctrl+U)',
    strikethrough: 'Tachado (Ctrl+D)',
    heading: 'Cabeçalho | Cabeçalho {level} (Ctrl+Shift+{level})',
    blockquote: 'Citação (Ctrl+>)',
    horizontal_rule: 'Linha horizontal',
    code: 'Código',
    bullet_list: 'Lista de marcadores (Ctrl+Shift+8)',
    ordered_list: 'Lista ordenada',
    undo: 'Desfazer (Ctrl+Z)',
    redo: 'Refazer (Ctrl+Shift+Z)',
    simple_text: 'Texto simples',
    table: 'Tabela',
    delete_table: 'Remover tabela',
    delete_row: 'Remover linha',
    delete_column: 'Remover coluna',
    reset_row_content: "Remove conteúdo da linha",
    reset_row_style: "Remove estilos da linha",
    reset_column: "Remove estilos da coluna",
    reset_cell: "Remove estilos da célula",
    add_row_before: 'Adicionar linha antes',
    add_row_after: 'Adicionar linha depois',
    add_column: 'Adicionar coluna',
    add_column_before: 'Adicionar coluna antes',
    add_column_after: 'Adicionar coluna depois',
    toggle_cell_merge: 'Alternar mesclagem de células',
    icon: 'Ícone',
    advanced_editing: 'Edição avançada | Edição simples',
    legend: "Legenda",
    cell_properties: "Propriedades da célula",
    cell_content: "Conteúdo da célula",
  },
  color_pattern: "Padrão de cor",
  single: "Única",
  gradient: "Gradiente",
  solid: "Solida",
  piled: "Empilhada",
  equipment_history: 'Histórico do conector',
  disable_password_change: "Bloquear troca de senha",
  canvas_properties: "Propriedades do canvas",
  unacknowledgement: "Não reconhecido",
  updated_at: "Atualizado em",
  deleted_at: "Removido em",
  load_more: 'Carregar mais',
  more: "Mais",
  source: "Origem",
  alias: 'Apelido | Apelidos',
  color: 'Cor | Cores',
  toolbar: "Barra de ferramentas",
  new_button: "Novo botão",
  button: "Botão | Botões",
  apply_to_other_panels: "Aplicar à outros painéis",
  up_to: "Até",
  dataset_will_be_available_for_download: "Os dados ficarão disponíveis para download.",
  there_is_already_an_active_download_task: "Existe uma operação de download aguardando.",
  days: "Dias",
  retry: "Tentar novamente",
  request_status: "Status da requisição",
  last_request: "Última requisição",
  available: "Disponível",
  not_available: "Não disponível",
  background: "Fundo",
  both: "Ambos",
  icon: "Ícone | Ícones",
  preferences: "Preferências",
  date_time: "Data e hora",
  numeric: "Numérico",
  date_time_format: "Formato data e hora",
  numeric_format: "Formato numérico",
  automatic: "Automático",
  chart: "Gráfico",
  y_axle: "Eixo Y",
  x_axle: "Eixo X",
  last_release: 'Última liberação',
  news_from_release: 'Novidades da versão {version}',
  portal_updated: 'Portal atualizado! Confira as novidades da versão {version}',
  see: 'Ver',
  logical_expression: "Expressão lógica",
  new_logical_expression: "Adicionar expressão lógica",
  always: "Sempre",
  always_visible: "Sempre visível",
  never: "Nunca",
  hidden: "Oculta",
  scroll_bars: "Barras de rolagem",
  actions: "Ações",
  if: "Se",
  then: "Então",
  expression: "Expressão",
  add_expression: "Adicionar expressão",
  remove_expression: "Remover expressão",
  edit_expression: "Editar expressão",
  year: "Ano",
  yearly: "Anual",
  month: "Mês",
  monthly: "Mensal",
  different_system_timezone_detected: "Detectado diferença entre timezones",
  screen_process_area: 'Restringe visualização da tela aos usuários com acesso à Área de Processo selecionada.',
  data_amount: "Quantidade de dados",
  how_often_do_you_want_to_be_charged: "Qual a periodicidade que deseja ser cobrado?",
  save_on_the_annual_plan: "Economize 16% no plano anual",
  buy: "Contratar",
  please_accept_the_terms_and_conditions: "Aceite os termos e condições, por favor.",
  start: "Início",
  stop: "Fim",
  configure: "Configurar",
  orientation: "Orientação",
  vertical: "Vertical",
  horizontal: "Horizontal",
  removal_pending_command: "Remover ultimo comando pendente",
  created_at: "Criado em",
  specific_properties: "Propriedades específicas",
  group_permissions: {
    "EQUIPAMENTO-CADASTRO": "Permite o cadastro de conectores",
    "EQUIPAMENTO-ACESSO": "Permite a visualização de conectores (Lista de conectores)",
    "EQUIPAMENTO-ESCRITA": "Permite a alteração de conectores cadastrados",
    "ESTAÇÃO-ESCRITA": "Permite a alteração de dispositivos",
    "ESTAÇÃO-ACESSO": "Permite a visualização de dispositivos (Lista de dispositivos e Home)",
    "ESTAÇÃO-CADASTRO": "Permite o cadastro de dispositivos",
    "DADO-CADASTRO": "Permite o cadastro de dados",
    "DADO-ACESSO": "Permite a visualização de dados",
    "DADO-ESCRITA": "Permite a alteração de dados",
    "ALARME-CADASTRO": "Permite o cadastro de alarmes",
    "ALARME-ACESSO": "Permite a visualização de alarmes",
    "ALARME-RECONHECIMENTO": "Permite a realização reconhecimento de alarmes ativos",
    "ÁREA DE PROCESSO-CADASTRO": "Permite o cadastro de Áreas de Processo",
    "ÁREA DE PROCESSO-ACESSO": "Permite a visualização de Áreas de Processo",
    "ÁREA DE PROCESSO-ESCRITA": "Permite a alteração de Áreas de Processo",
    "GRUPO DE USUÁRIOS-CADASTRO": "Permite o cadastro de Grupos de Usuários",
    "GRUPO DE USUÁRIOS-ACESSO": "Permite a visualização de Grupos de Usuários",
    "GRUPO DE USUÁRIOS-ESCRITA": "Permite a alteração de Grupos de Usuários",
    "MEMBRO DE CONTRATO-CADASTRO": "Permite a adição de Membros ao contrato",
    "MEMBRO DE CONTRATO-ACESSO": "Permite a visualização dos Membros do contrato",
    "MEMBRO DE CONTRATO-ESCRITA": "Permite a alteração dos privilégios de um Membro do contrato",
    "CONTRATO-ACESSO": "Permite o acesso ao contrato",
    "CONTRATO-ESCRITA": "Permite a alteração do contrato",
    "TOKEN-ACESSO-REMOTO-CADASTRO": "Permite a criação de Tokens de acesso remoto",
    "TOKEN-ACESSO-REMOTO-ACESSO": "Permite a visualização de Tokens de acesso remoto cadastrados",
    "TOKEN-ACESSO-REMOTO-ESCRITA": "Permite a alteração de Tokens de acesso remoto cadastrados",
    "LISTA DE TEXTO - CADASTRO": "Permite o cadastro de Listas de Texto",
    "LISTA DE TEXTO - ACESSO": "Permite o acesso de Listas de Texto",
    "LISTA DE TEXTO - ESCRITA": "Permite a alteração de Listas de Texto",
    "NOTIFICAÇÕES - CADASTRO": "Permite o cadastro de notificações",
    "NOTIFICAÇÕES - ACESSO": "Permite o acesso a notificações",
    "NOTIFICAÇÕES - ESCRITA": "Permite a alteração de notificações",
    "HISTORICO-DADO-ACESSO": "Permite a visualização do histórico de dados",
    "HISTORICO-SISTEMA-ACESSO": "Permite a visualização do histórico de eventos do sistema",
    "HISTORICO-ALARME-ACESSO": "Permite a visualização do historico de alarmes",
    "SINOTICO-ACESSO": "Permite uso de painéis sinóticos",
    "MANUTENCAO-ACESSO": "Permite acesso a área de manutenção",
    "TELA-CUSTOMIZADA-ESCRITA": "Permite a alteração de Telas customizadas",
    "TELA-CUSTOMIZADA-ACESSO": "Permite o acesso a Telas customizadas",
    "TELA-CUSTOMIZADA-CADASTRO": "Permite o cadastro de Telas customizadas",
  },
  of: 'd{gender} | d{gender}s',
  nothing_here: 'Nada por aqui',
  no_connector_found: 'Nenhum conector foi encontrato para exibirmos no Mapa',
  null: 'Nulo',
  cell: "Célula|Células",
  default_value: "Valor padrão",
  data_series: "Série de dados",
  serie: "Série",
  show_symbol: "Símbolo",
  smooth: "Suavizado",
  animation: "Animação",
  add_serie: "Adicionar série",
  sin: "Senoidal",
  triangle: "Triangular",
  square: "Quadrada",
  line: "Linha",
  bar: "Barras",
  pie: "Torta",
  area: "Área",
  connector_property: 'Propriedade do conector | Propriedades do conector',
  device_property: 'Propriedade do dispositivo | Propriedades do dispositivo',
  text_search: "Pesquisa por texto",
  connector_access: "Acesso ao conector",
  redirect_url: "URL de redirecionamento",
  access_timeout: "Duração do acesso",
  filename: "Arquivo",
  percentage: "Percentual",
  multiple_levels: "Vários níveis?",
  bar_color: "Cor padrão da barra",
  show_marker: "Mostrar marcador",
  dash: "Traço",
  custumize_view: 'Customizar visualização',
  wave_form: 'Forma de onda',
  word_wrap: "Quebra de linha",
  due_date: "Data de vencimento",
  table: "Tabela",
  style: "Estilo",
  bordered: "Com bordas",
  condensed: "Condensado",
  striped: "Listrado",
  hover: "Enfatizar ao apontar",
  size: "Tamanho",
  no_result_found: 'Nenhum resultado encontrado',
  type_to_search: 'Digite para pesquisar',
  aquisition_interval: "Intervalo válido para aquisição do dado",
  aquisition_interval_local_data: "Intervalo válido para valor do dado",
  system: "Sistema",
  simulation: "Simulação",
  simulator: "Simulador",
  topic: "Tópico",
  user_manual: "Manual do Usuário",
  toggle_dropdown: 'Alternar menu suspenso',
  engine_version: 'Versão do editor',
  dataset: "Conjunto de dados",
  target_cell: "Célula alvo",
  row_number: "Número da linha",
  missing_values: "Valores ausentes",
  empty_cells: "Células vazias",
  last_value: "Último valor",
  shows_last_recorded_value: "Mostra último valor registrado",
  linear_interpolation: "Interpolação linear",
  standard: "Padrão",
  no_notifications: 'Tudo limpo por enquanto.',
  see_all: "Ver todas",
  see_more: "Ver mais",
  you_have_unread_notifications: 'Você tem 1 notificação não lida | Você tem {count} notificações não lidas',
  last_notification: 'Última notificação | Últimas notificações',
  commitment: "Comprometimento",
  default_dashboard_tab: 'Aba padrão do dashboard do contrato',
  create_new_tab: {
    title: 'Criar nova aba',
    description: 'Adicione uma nova visualização ao dashboard do contrato utilizando uma Tela existente.',
    title_hint: 'Será o nome da tela, por padrão.',
  },
  optional: 'Opcional',
  removing_tabs: 'Removendo abas:',
  changes_will_be_performed_after_saving: "As alterações só serão efetuadas ao clicar em \"{save}\".",
  i_know: 'Estou ciente',
  open: 'Abrir',
  openned: "Aberto",
  mobile: "Celular",
  mobile_native_printout: "Nativa em dispositivos móveis",
  company: "Empresa",
  send_invitation: "Enviar convite",
  invitation_sent: "Convite enviado",
  no_screens_available: 'Sem telas disponíveis',
  hour: "Hora|Horas",
  second: "Segundo | Segundos",
  minute: "Minuto | Minutos",
  millisecond: "Milissegundo | Milissegundos",
  day: "Dia | Dias",
  duration: "Duração",
  default_interval: "Intervalo padrão",
  samples_out_of_boundaries: "Amostras fora do intervalo",
  leave_them_empty: "Deixe-as vazias",
  fit_in_time_window: "Ajuste para intervalo",
  mqtt_parameters: "MQTT - Parâmetros",
  secure_port: "Porta segura",
  authenticated_port: "Porta Autenticação",
  custom_page: "Página customizada",
  new_field: "Novo campo",
  configured_items: "Itens configurados",
  required: "Obrigatório",
  restrictions: "Restrições",
  access_key: "Chave de acesso|Chaves de acesso",
  create_a_token: "Criar um token",
  token_type: "Tipo de token",
  save_token: "Salvar token",
  save_link: "Salvar link",
  state_indicators: "Indicadores de estado",
  pagination: "Paginação",
  search_by_text: "Pesquisa por texto",
  select_control_to_execute_action: 'Selecione um controle para executar esta ação.',
  this_panel_is_set_as_default: "Este é o painel padrão",
  footer: "Rodapé",
  card_configuration: "Configuração dos cartões",
  dynamic_color: "Cor dinâmica",
  dynamic_background_color: "Cor de fundo dinâmica?",
  arc: "Arco",
  pointer: "Ponteiro",
  rays: "Raios",
  scale: "Escala",
  mqtt_qos: "MQTT QoS",
  mqtt_retain: "MQTT Retain",
  mqtt_topic: "Tópico MQTT",
  arc_and_scale: "Arco e escala",
  split: "Dividir",
  column_title: "Título da coluna",
  not_applicable: "Não aplicável",
  at_most_once: "No máximo uma vez",
  at_least_once: "Pelo menos uma vez",
  exactly_once: "Uma vez",
  general: 'Geral',
  time_window: 'Janela de tempo',
  time_window_desc: `
    Interválo máximo de tempo entre o primeiro e o último valor exibido no gráfico.
    <br><em>Obs: valores dentro do período ainda serão descartados caso o total exceda 100.</em>`,
  trailing_value: 'Incluir último valor',
  trailing_value_desc: 'Insere o último valor registrado do dado no início do gráfico.',
  refresh_interval: 'Intervalo de atualização',
  refresh_interval_desc: 'Tempo entre a atualização dos valores do gráfico.',
  secondary: "Secundário",
  customizable_controls: "Controles customizáveis",
  waiting: "Aguardando",
  history_type: "Histórico",
  keep_selected: "Manter o selecionado",
  custom_format: "Formato customizado",
  customized: "Customizado",
  position: "Posição",
  unamed: 'Não nomeado',
  aggregate: "Agrupar",
  confirmation: "Confirmação",
  require_confirmation: "Requer confirmação?",
  alert_icons: {
    warning: "Atenção",
    info: "Informação",
    error: "Erro",
    success: "Sucesso",
    none: "Nenhum"
  },
  channel: "Canal|Canais",
  daily: "Diário",
  telegram: "Telegram",
  whatsapp: "Whatsapp",
  sms: "SMS",
  identifier: "Identificador",
  save_on_tab: "TAB salva?",
  connector_standard: "Padrão do conector",
  editor_device_list: "Dispositivos em edição",
  expression_editor: "Editor de expressão",
  javascript_editor: "Editor JavaScript",
  clear: 'Limpar',
  please_wait: "Por favor, aguarde{timeout}...",
  please_wait_no_timeout: "Por favor, aguarde...",
  token_not_found: "Token não encontrado",
  invalid_token: "Token inválido ou expirado. Solicite um novo token",
  visibility: "Visibilidade",
  no_process_area_selected: 'Nenhuma Área selecionada',
  no_process_area_available: 'Áreas de Processo indisponíveis',
  restrict_view: 'Restringir visualização',
  restrict_interaction: 'Restringir interação',
  certificate: "Certificado",
  item_copied: "Item copiado!",
  action_not_allowed: "Operação não permitida",
  there_are_invalid_panels_on_this_screen: "A tela contém painéis inválidos.\nCorrija-os para publicar.",
  screen_name: "Nome da tela",
  restore_previous_versions: "Restaurar Versões Anteriores",
  on_success_navigate_to: "Visualizar dashboard após execução da ação",
  overwrite_draft: "Sobrescrever rascunho",
  new_draft: "Novo rascunho",
  user_identification: "Identificação do usuario",
  version_older_than_the_latest: "%s versões atrás",
  latest_version: "Última versão",
  realtime_update: "Atualização em tempo real",
  no_connection: 'Falha na conexão. Verifique seu acesso à Internet e tente novamente.',
  unknown_login_error: 'Um erro ocorreu. Tente novamente ou contate o suporte.',
  rate_limit: 'Muitas tentativas de login foram feitas em um curto período de tempo. Tente novamente em {param} segundo... | Muitas tentativas de login foram feitas em um curto período de tempo. Tente novamente em {param} segundos...',
  login_attempt_limit: 'Limite de tentativas de login excedido. Tente novamente. | Limite de tentativas de login excedido. Tente novamente em {param} "minuto". | Limite de tentativas de login excedido. Tente novamente em {param} minutos.',
  data_format: "Formato do dado",
  style_and_format_copy: "Cópia de estilo e formatação",
  read_value: "Valor lido",
  no_panels_available: "Nenhum painel disponivel",
  available_panels: "Painéis disponíveis",
  available_layouts: "Layouts disponíveis",
  dashboard_panels: "Painéis na tela",
  layout: "Layout|Layouts",
  remove_layout: "Remover layout",
  add_layout: "Adicionar layout",
  available_for_mapping: "Disponivel para mapeamento",
  data_mirroring: "Espelhamento de dados",
  replace: "Substituir",
  data_replacement: "Substituição de dados",
  data_mapping: "Mapeamento de dados",
  data_mapping_not_available: "Mapeamento de dados não disponível",
  data_sharing: "Compartilhamento de dados",
  destination_connector: "Conector de destino",
  no_mapped_destination_connector: "Nenhum conector de destino mapeado",
  not_assigned: "Não mapeado",
  select_a_connector: "Selecionar um conector",
  save_mapping: "Salvar mapeamento",
  remove_mapping: "Remover mapeamento",
  mapping: "Mapeamento",
  tab_label: {
    connector: "Conector|Conectores",
    device: "Dispositivo|Dispositivos",
    data: "Dado|Dados",
    alarm: "Alarme|Alarmes",
    resource: "Recurso | Recursos",
    consumption: "Consumo",
    custom_fields: "Campo Customizado|Campos Customizados",
    notification: "Notificação|Notificações",
    access: "Acesso|Acessos",
    general: "Geral",
    data_exibition: "Exibição de dado|Exibição de dados",
    tab_connector_home: "Configuração básica do conector",
    tab_connector_address: "Informações de localização do conector",
    tab_connector_notification: "Configuração de notificações e alertas",
    tab_connector_keys: "Chaves públicas e privadas para acesso ao conector",
    tab_data_list: "Configurações para visualização de dados",
    tab_connector_data_mirroring: "Compartilhamento de dados com outros conectores",
    tab_custom_fields: "Campos customizados do conector",
    tab_resource: "Dispositivos disponíveis",
    tab_history_usage_statistics: "Estatísticas de Uso do Histórico de Dados",
    tab_connector_notes: "Anotações"
  },
  access_mode: {
    read_only: "Apenas Leitura",
    write_only: "Apenas Escrita",
    read_write: "Leitura e Escrita"
  },
  no_item_recorded: "Você ainda não possui nenhum item registrado",
  password_change_needed: "É necessário que troque sua senha para continuar a utilizar o sistema",
  password_change: "Mudança de senha",
  min_password_length: "Mínimo de caracteres",
  max_password_length: "Máximo de caracteres",
  min_numbers: "Mínimo de números",
  min_lower_characters: "Mínimo de caracteres minúsculos",
  min_upper_characters: "Mínimo de caracteres maiúsculos",
  min_special_characters: 'Mínimo de caracteres especiais (Ex: "@", "_", "(", ")", etc)',
  card: "Cartão | Cartões",
  available_cards: "Cartões disponíveis",
  configured_cards: "Cartões configurados",
  no_cards_configured: "Nenhum cartão configurado",
  string_search: "Pesquisa por texto",
  show_new_connector_button: "Mostrar botão para novo conector?",
  filter_enabled: "Filtro habilitado",
  progress: "Progresso",
  progress_bar: "Barra de progress",
  display: "Display",
  insert_full_name: "Insira o nome completo",
  show_at_position: "Mostrar na posição",
  contract_settings: "Configurações do contrato",
  reset_default_tab: "Restaurar para o padrão do contrato ({tab})",
  default_dashboard_for_user: "Aba padrão da Home do usuário",
  default_dashboard_for_contract: "Aba padrão da Home do contrato",
  contract_tabs: 'Abas do contrato',
  banner: "Banner",
  field: "Campo | Campos",
  not_enough_room: "Dimensão inválida",
  showing_n_records_in_this_page: 'Exibindo {items} registro | Exibindo {items} registros',
  dashboard: "Dashboard",
  icon_label: "Ícone {icon}",
  usage_statistics: 'Estatísticas de Uso',
  system_usage_statistics: 'Estatísticas de Uso do Sistema',
  usage: 'Utilização',
  amount: 'Quantidade',
  connectors_model_count: 'Conectores Modelo',
  devices_enabled_count: 'Habilitados',
  devices_disabled_count: 'Desabilitados',
  devices_third_party_count: "de Terceiros",
  devices_hi_count: 'da HI Tecnologia',
  data_history_enabled_count: 'Com Histórico',
  data_history_disabled_count: 'Sem Histórico',
  data_disabled_count: 'Desabilitados',
  alarms_history_enabled_count: 'Com Histórico',
  alarms_history_disabled_count: 'Sem Histórico',
  alarms_disabled_count: 'Alarmes Desabilitados',
  alarms_count: 'Alarmes',
  process_areas_count: 'Áreas de Processo',
  user_groups_count: 'Grupos de Permissões',
  screens_count: 'Telas',
  users_count: 'Usuários',
  unlimited: 'Ilimitado',
  device_means_connectors_text: 'Dispositivos ou Conectores MQTT',
  third_party: 'de terceiros',
  expand_details: 'Expandir detalhes',
  collapse_details: 'Colapsar detalhes',
  no_item_found: 'Nenhum {item} encontrado | Nenhuma {item} encontrada',
  remove_user_from_hierarchy: 'Remover usuário de {hierarchy} | Remover usuários de {hierarchy}',
  and_many_others: 'e um outro... | e {amount} outros...',
  and: 'e',
  select_user_to_continue: 'Selecione um usuário para continuar',
  discard_changes: 'Descartar mudanças?',
  continue: 'Continue',
  to_continue: 'Continuar',
  abort: "Abortar",
  you_have_unsaved_changes: 'Você irá descartar qualquer alteração não salva.',
  dont_ask_again: 'Não perguntar novamente',
  in_exhibition: 'em exibição | em&nbsp;exibição',
  item: 'Item | Itens',
  no_users_in_group: 'Nenhum usuário existente neste grupo',
  no_users_in_process_area: 'Nenhum usuário existente nesta área de processo',
  no_process_areas: 'Sem Áreas de Processo disponíveis',
  no_groups: 'Sem Grupos disponíveis',
  select_adding_users_to_permission_group: 'Selecione usuários para adicionar ao grupo de permissões',
  select_adding_users_to_process_area: 'Selecione usuários para adicionar à área de processo',
  select_adding_process_areas: 'Selecionar Áreas de Processo associadas',
  select_adding_groups: 'Selecionar Grupos de Permissões associados',
  existing_users_already_in_permission_group: 'Usuários cadastrados já estão presente neste grupo de permissões',
  existing_users_already_in_process_area: 'Usuários cadastrados já estão presente nesta área de processo',
  stateless_access_control_text: 'Selecione usuários para editar ou clique no botão para adicionar novos ao {hierarchy} | Selecione usuários para editar ou clique no botão para adicionar novos à {hierarchy}',
  you_cant_edit_contract_owner: 'Você não pode alterar os privilégios do dono do contrato.',
  associate_process_areas_with_users: 'Associe Áreas de Processo aos usuários do grupo',
  associate_groups_with_users: 'Associe Grupos de Permissões aos usuários da área',
  search_group_by_fields: 'Pesquisar grupo por nome, descrição ou usuários',
  select_a_process_area: 'Selecione uma área',
  search_process_area_by_fields: 'Pesquisar área de processo por nome, descrição ou usuários',
  hierarchy: "Hierarquia",
  features: {
    'new-access-control': {
      name: 'Gerência de Privilégios',
      description: 'Nova aba para facilitar gerência de privilégios de usuários do contrato.'
    }
  },
  experimental_feature: 'Funcionalide experimental | Funcionalidades experimentais',
  open_documentation: 'Abrir manual',
  onboarding: {
    'new-access-control': {
      title: 'Nova aba',
      step1: 'Utilize a aba Privilégios para definir as associações de Usuários, Grupos de Permissões e Áreas de Processo com mais facilidade.',
      step2: 'Visualize os Usuários divididos por uma hierarquia clara, seja por Grupos de Permissões ou Áreas de Processo.',
      step3: 'Ao selecionar um Grupo de Permissão, você pode ver todos os Usuários presentes nele em conjunto com as Áreas de Processo que possuem.',
      step4: 'É possível adicionar um ou mais Usuários existentes de uma vez ao Grupo de Permissão selecionado, definindo o acesso compartilhado a uma ou mais Áreas de Processo também.',
      step5: `
        Pronto! Você já sabe o básico dessa nova interface. Em caso de dúvida, consulte nosso <a href="{manual}" target="_blank">manual</a> para mais detalhes.
        <br><br>
        Deseja definir essa interface como a forma padrão de alteração de privilégios?
        <br><small>(Você pode voltar para antiga a qualquer momento em Preferências.)</small>
      `
    }
  },
  skip_tour: 'Pular tour',
  next: 'Próximo',
  previous: 'Anterior',
  finish: 'Terminar',
  enable_zoom: "Habilitar zoom",
  variables: "Variáveis",
  current_panel_dimension: "Dimensão atual do painel",
  original_panel_dimension: "Dimensão de referência do canvas",
  click_to_edit: "Clique para editar",
  ctrl_click_for_more_options: "Ctrl+Clique para mais opções",
  drag_to_move: "Arraste para mover",
  screen_properties: "Propriedades da tela",
  selection: "Seleção",
  delete_this_screen: "Deletar essa tela",
  download_this_screen: "Baixar essa tela",
  maintenance: "Manutenção",
  show_all: "Mostrar todos",
  identification: "Identificação",
  tags: "Marcadores",
  connector_instances: "Instâncias deste conector|Instâncias de conectores",
  update_at_startup: "Filtrar ao abrir a página",
  show_at_startup: "Mostrar ao iniciar",
  preview: "Pre-visualização",
  alarm_level: {
    critical: "Critico",
    error: "Erro",
    warning: "Aviso",
    info: "Informação",
    debug: "Depuração",
    not_set: "Não definido",
    low_low: "LL (Muito baixo)",
    low: "L (Baixo)",
    high: "H (Alto)",
    high_high: "HH (Muito alto)",
    base_low: "Baixo",
    base_medium: "Médio",
    base_high: "Alto"
  },
  security_code: "Código de Segurança",
  fill_security_code: "Informe o Código de Segurança.",
  fill_security_code_email: "Informe o Código de Segurança enviado no seu e-mail.",
  fill_security_code_app: "Informe o Código de Segurança do seu aplicativo de Autenticação de Dois Fatores.",
  fill_security_code_both: "Informe o Código de Segurança do seu aplicativo de Autenticação de Dois Fatores ou do seu e-mail.",
  security: "Segurança",
  two_factor_authentication_explanation: "Para sua segurança, este portal implementa o acesso com Autenticação de Dois Fatores.",
  optional_resource_configuration: "A configuração deste recurso é opcional.",
  required_resource_configuration: "A configuração deste recurso é obrigatória.",
  two_factor_authentication: "Autenticação de Dois Fatores",
  two_factor_authentication_email: "E-mail",
  two_factor_authentication_email_description: "Utilize um código de uso único recebido em seu e-mail para validar a autenticidade de seu acesso.",
  two_factor_authentication_app: "Aplicativo de Autenticação Segura",
  two_factor_authentication_app_description: "Utilize um código exibido em um Aplicativo de Autenticação Segura para validar a autenticidade de seu acesso.",
  two_factor_authentication_use_this_qrcode: "Use este QRCode para cadastrar este portal no seu Aplicativo de Autenticação Segura.",
  two_factor_authentication_suggestions: "Aplicativos sugeridos: Google Authenticator, Authy, FreeOTP",
  summary: "Resumo",
  billing_information: "Informações de Faturamento",
  invoices: "Faturas",
  tax_invoices: "Notas Fiscais",
  contracted_plan: "Plano Contratado",
  data_range: "Faixa de Dados",
  periodicity: "Periodicidade",
  plan_status: "Status",
  plan_value: "Valor do Plano",
  expires_at: "Validade",
  free: "Gratuito",
  company_name: "Nome Completo / Razão Social",
  cpf_cnpj: "CPF / CNPJ",
  area_plus_phone: "DDD + Telefone",
  zipcode: "CEP",
  number: "Número",
  complement: "Complemento",
  email_billing: "E-mail para Envio de Faturas e Notas Fiscais",
  invalid_mask: "Máscara Inválida",
  invoice_due_date: "Data de Vencimento",
  invoice_payment_date: "Data de Pagamento",
  invoice_status: "Situação",
  date_range: "Período",
  filter_by: "Filtrar por",
  discount: "Desconto",
  bank_slip: "Boleto Bancário",
  invoice: "Fatura",
  tax_invoice: "Nota Fiscal",
  resend_invoice_email: "Reenviar Fatura por E-mail",
  invoice_status_all: "Todas",
  invoice_status_paid: "Liquidadas",
  invoice_status_pending: "Pendentes",
  invoice_status_cancelled: "Canceladas",
  invoice_status_emitted: "Emitidas",
  invoice_status_invalidated: "Invalidadas",
  invoice_serial_number: "Número de Série",
  invoice_resend_email_message: "O boleto desta fatura será reenviada ao e-mail cadastrado nas Informações de Faturamento.",
  invoice_resend_email_success: "O boleto da fatura foi enviado com sucesso!",
  success: "Sucesso",
  series: "Série",
  range_from: "De",
  range_to: "Até",
  your_current_plan: "Seu Plano Atual",
  your_new_plan: "Seu Novo Plano",
  do_contract: "Contratar",
  select_plan: "Selecione o Plano",
  selected_plan: "Plano Selecionado",
  starts_with: "Começa em",
  complete_billing_information: "Complete suas Informações de Faturamento",
  finish_contracting: "Finalizar Contratação",
  tax_invoice_unavailable: "Nota Fiscal inexistente.",
  bank_slip_unavailable: "Boleto Bancário indisponível. Fatura Paga/Cancelada/etc.",
  resend_invoice_email_unavailable: "Reenvio de Fatura por E-mail indisponível. Fatura Paga/Cancelada/etc.",
  about_the_billing: "Sobre a Cobrança",
  billing_details_information: "As informações de faturamento serão utilizadas para geração das faturas e boletos que serão enviados no e-mail cadastrado. Por favor, preencha os dados com atenção.",
  no_serial_number: "Sem Número de Série",
  warning: "Atenção",
  register_fill_all_fields: "Preencha todos os campos antes de continuar.",
  register_contract_selected_plan: "Contratar Plano selecionado.",
  account_locked: {
    "warning": "Atenção",
    "contract_requires_attention": "O contrato acessado requer atenção.",
    "please_contact_admin": "Por favor, contate o administrador do contrato para mais informações",
    "test_over_title": "Seu período de testes no Portal de Telemetria terminou.",
    "test_over_subtitle": "Esperamos que a experiência tenha sido ótima!",
    "test_over_text_1": "Para continuar utilizando o Portal e seus recursos, é necessário que finalize a contratação de um plano.",
    "test_over_text_2": "Clique no botão abaixo para escolher um plano e continuar com acesso.",
    "payment_not_confirmed_title": "Sua conta está com pagamento pendente.",
    "payment_not_confirmed_subtitle": "O período adicional para o pagamento do seu plano se expirou em:",
    "payment_not_confirmed_text_1": "Para continuar utilizando o Portal e seus recursos, é necessário que realize o pagamento pendente.",
    "payment_not_confirmed_text_2": "Para maiores informações, acesse a opção <a href=\"/dashboard/settings/billing\">\"Plano\"</a> no menu de usuário."
  },
  print_tax_invoice: "Imprimir espelho de Nota Fiscal.",
  tax_invoice_has_no_print: "Nota Fiscal não possui dados para geração de espelho.",
  contact_commercial_department: "Contate nosso departamento comercial.",
  forbidden_access: "Acesso Negado.",
  page_brake: "Quebra de página",
  page_settings: "Configuração de página",
  portrait: "Retrato",
  landscape: "Paisagem",
  script: "Script|Scripts",
  new_screen: "Nova tela",
  import_connectors: "Importar Conectores",
  import_devices: "Importar Dispositivos",
  import_data: "Importar Dados",
  import_alarms: "Importar Alarmes",
  success_import_message: "Registros importados sem erros.",
  drag_file: "Arraste um arquivo aqui ou clique para carregar",
  import_file_accepted_formats: "Formato aceito para cadastro: CSV (apenas {entity}).",
  import_file_accepted_formats_connector: "Formatos aceitos para cadastro: CSV (apenas os Conectores) ou HPC (Conectores e seus recursos).",
  itself: "O próprio {name}",
  groups: "Grupo|Grupos",
  keep_screen_references_among_devices: "Utilizar recursos/configurações do dispositivo",
  first_select_device: "Para importar Dados, primeiro selecione um Dispositivo.",
  first_select_data: "Para importar Alarmes, primeiro selecione um Dado.",
  critical_changes_detected: "Alterações críticas detectadas<br>{item}",
  instance_creation_not_allowed: "Esta é uma instância de conector de modelo. Não é permitida a criação de novos recursos.",
  download_base_file_text: "Faça o download do arquivo CSV de exemplo:",
  basic_format: "Formato Básico",
  full_format: "Formato Completo",
  csv_import_file_instructions: "O padrão de CSV a ser recebido é com separador de ponto e vírgula sem espaços.",
  remaining_characters: "Caracteres restantes",
  mass_remove: "Exclusão de vários registros",
  n_records_selected: '1 registro selecionado | {amount} registros selecionados',
  some_selected_items_cant_be_removed: 'Alguns dos itens selecionados não podem ser removidos.',
  wish_to_unselect: 'Deseja removê-los da seleção automaticamente?',
  history_warning: 'Atenção',
  history_data_limit_reached: 'Limite de registros atingido. Considere reduzir o período e/ou dados selecionados.',
  history_alarm_limit_reached: 'Limite de registros atingido. Considere reduzir o período e/ou alarmes selecionados.',
  notification_mode: 'Modo de notificação',
  minimum_height: "Altura mínima",
  maximum_height: "Altura máxima",
  multiple_data_write_tab_name: "Escrita de valores",
  multiple_data_write_alert_title: "Nenhum dado selecionado para realizar escrita",
  multiple_data_write_alert_description: "Selecione pelo menos um dado para escrever um valor.",
  alarm_trigger_condition: "Condição",
  too_many_requests: "Número de requisições excedido. Por favor, aguarde alguns minutos e tente novamente.",
  bad_request: "Requisição inválida",
  login_forbidden_access: "Credenciais válidas, porém usuário não tem permissão em nenhum contrato. Acesso negado.",
  login_no_contract: "Credenciais válidas, porém usuário não tem permissão em nenhum contrato. Acesso negado.",
  login_no_permissions_allowed: "Credenciais válidas, porém usuário não tem permissões associadas. Acesso negado.",
  login_no_contract_permissions_allowed: "Credenciais válidas, porém usuário não tem permissões associadas ao Contrato selecionado. Acesso negado.",
  local_data: "Dado Local",
  machine: "Máquina",
  add_new_machine: "Adicionar máquina",
  reason_list: "Lista de Motivos de Parada",
  casualty_list: "Lista de Motivos de Perda",
  add_reason: "Adicionar Motivo de Parada",
  reason_text: "Texto do Motivo de Parada",
  add_casualty: "Adicionar Motivo de Perda",
  casualty_text: "Texto do Motivo de Perda",
  empty_list: "Lista Vazia.",
  click_add_to_start: "Clique Adicionar para iniciar a inserção.",
  is_planned_stop: "Parada planejada",
  default_url_button_text: "Abrir Tela do Conector",
  import_casualty: "Importar Lista de Perdas",
  import_reason: "Importar Lista de Motivos",
  confirmation_pending_save_message: "Há alterações pendentes de confirmação. Salve para confirmar as modificações realizadas.",
  incompatible_data_protocol_message: "Foram encontrados Dados incompatíveis com o novo protocolo. Se prosseguir, a coleta destes Dados será apenas possível após a configuração dos campos incompatíveis (Tipo e/ou Endereço de memória)",
  changed_model_message: "O modelo deste Conector será alterado. Isto poderá causar a exclusão de Dispositivos, Dados e Alarmes cujos seus respectivos nomes não sejam iguais aos recursos cadastrados no Conector de Modelo.",
  memory_type_not_selected: "Tipo de memória do dado não preenchido.",
  municipal_registration: "Inscrição Municipal",
  state_registration: "Inscrição Estadual",
  back_to_top: "Voltar ao topo",
  displaying_data_as: "Exibindo Dado por",
  identity_embedded_app: "Identificação na Aplicação",
  data_name: "Nome do Dado",
  click_to_change_to: "Clique para alterar para",
  double_click_or_drag_to_insert: "Arraste ou dê duplo clique para adicionar ao {item}",
  last_activity_at: "Última atividade",
  grouped: "Agrupado",
  in_line: "Alinhado",
  minute: "minuto | minutos",
  hour: "hora | horas",
  default: "Padrão",
  same_as_connector: "Mesmo que no Conector",
  history_usage_statistics: "Estatísticas do Histórico",
  last_n_days_total: "Total dos últimos {count} dias de registros",
  sum: "Soma",
  day_average: "Média por Dia",
  n_days_ago: "Há {count} dias",
  history_daily_consumption_allowed_connector: "Uso diário ideal (máximo)",
  history_monthly_consumption_allowed_connector: "Cota mensal por Dado",
  daily_consumption: "Consumo diário",
  monthly_consumption: "Consumo mensal",
  data_with_history: "Dados com histórico",
  blocked_data: "Dados bloqueados para histórico",
  collecting_data: "Registrando",
  current_month_read: "Total do mês atual",
  day_1_consumption: "Uso Diário (mais recente)",
  day_2_consumption: "Uso Diário (anterior)",
  day_3_consumption: "Uso Diário (anterior)",
  current_month_usage: "% Uso Mensal",
  data_history_usage_stable: "Uso de histórico se manteve.",
  data_history_usage_went_up: "Uso de histórico de dados aumentou.",
  data_history_usage_went_down: "Uso de histórico de dados diminuiu.",
  no_data: "Sem informações",
  no_data_description: "Não há nada para exibir.",
  connector_notes: "Anotações",
  remote_access_token: "Token de acesso remoto",
  public_access_links: "Links de acesso público",
  token_copied_to_clipboard: "Token copiado!",
  link_copied_to_clipboard: "Link copiado!",
  see_details: "Ver detalhes",
  history_enabled: "Histórico habilitado",
  since: "Desde",
  limit_n_reached: "Limite {count} excedido",
  limit_reached_message: "O uso de histórico deste Dado atingiu a cota mensal.",
  daily_limit_reached_message: "O uso de histórico deste Dado atingiu o uso diário ideal (máximo).",
  limit_n_reached_message: "O uso de histórico deste Dado atingiu o limite de {percent}% além da cota mensal.",
  limit_warning: "Ao ultrapassar a cota mensal, novos registros poderão ser descartados.",
  limit_n_reached_at: "Novos registros no histórico estão bloqueados para este Dado. Limite máximo excedido em {date}.",
  contract_history_rate: "Taxa de histórico do Contrato",
  hint_monthly_consumption: "Indica o limite máximo de registros DO MÊS ATUAL para cada Dado no Histórico, com base na taxa de histórico contratada e considerando o número de dias do mês.",
  hint_daily_consumption: "Indica o limite máximo de registros POR DIA previsto para cada Dado no Histórico de maneira que a cota mensal seja respeitada.",
  hint_history_data: "Indica o número de Dados com histórico habilitado neste Conector.",
  hint_history_data_blocked: "Indica o número de Dados cujo Histórico está bloqueado para novos registros(devido a ultrapassarem a cota mensal).",
  hint_contract_history_rate: "Indica a frequência máxima de registro do valor de um Dado no Histórico de Dados do Conector, ou seja, de quanto em quanto tempo o valor de um Dado deverá ser registrado no Histórico.",
  modal_title_data_history_usage: "Uso do Histórico para o Dado",
  record: "Registro | Registros",
  details_contract_state_test_expired: "Período de teste expirado.",
  details_contract_state_test_n_remaining: "Faltam {count} dias para o fim do teste.",
  details_contract_state_payment_pending: "Pagamento pendente.",
  from_the_clipboard_control: "Do controle da área de transferência",
  terms_of_service_update_menu_title: "Atualização nos Termos de Uso do Portal",
  terms_of_service_update_message: "Os Termos de Uso do Portal de Telemetria foram atualizados. Por favor, faça a leitura e o aceite o mais breve possível.",
  click_to_read: "Clique para ler.",
  unformatted_values: "Valores sem formatação",
  preset: "Predefinido",
  realtime: "Tempo real",
  you_really_want_to_restore: "Você deseja realmente restaurar este recurso?",
  you_will_need_to_fill_the_serial_number: "Você precisará preencher novamente o Número de Série.",
  removed: "Removido",
  pending: "Pendente",
  model_has_instances: "Este Conector de Modelo está associado a outros Conectores de Instância.",
  action_denied: "Ação negada",
  you_cant_restore_multiple_connectors: "Você não pode restaurar múltiplos conectores.",
  no_history_tolerance_value: "Qualquer variação.",
  no_images_to_import: "Nenhuma imagem pendente para importar!",
  there_are_n_images_to_import: "Há {count} imagens para recarregar nesta Tela.",
  there_are_n_images_with_error: "Há {count} imagens nesta Tela que resultaram em erro.",
  reupload: "Recarregar",
  auto_images_library_name: "Imagens Importadas",
  n_item_will_be_removed: "Um item será removido|{count} serão removidos",
  error_invalid_json_file: "Arquivo JSON de Tela é inválido.",
  error_file_too_large: "Tamanho do arquivo excedeu o limite aceito."
};