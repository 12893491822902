import Vue from "vue";

import DashboardService from "@/services/dashboard.js";
import ScreenService from "@/services/screen";
import { scriptDefaults } from "@/services/dashboard.js";

const initialState = () => ({
  content: "", // the script TEXT (file content),
  globalFunctions: null,
  activeIndex: 0, // right now only one script is suported,
  status: { id: "idle", text: "" },
  sync: 0
});

const mutations = {
  RESET(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
  SET_CONTENT(state, value) {
    state.content = value;
  },
  BUILD_GLOBAL_FUNCTIONS(state) {
    try {
      Vue.set(
        state,
        "globalFunctions",
        state.content ? JSON.parse(state.content) : null
      );
      Vue.set(state, "sync", state.sync + 1);
    } catch (error) {
      return null;
    }
  },
  SET_STATUS(state, value) {
    Vue.set(state, "status", value);
  },
  SYNC(state) {
    Vue.set(state, "sync", state.sync + 1);
  }
};

const actions = {
  setContent(context, content) {
    context.commit("SET_CONTENT", content);
  },
  validateContent(context, payload) {
    // validate and store after fetching or restoring a previous version
    return new Promise((resolve) => {
      try {
        if (payload) {
          JSON.parse(payload);
          context.commit("SET_CONTENT", payload);
          context.commit("BUILD_GLOBAL_FUNCTIONS");
          context.commit("SET_STATUS", { id: "idle", text: "" });
          resolve(true);
        } else {
          // todo: something wrong...
          context.commit("SYNC");
          context.commit("SET_STATUS", { id: "error", text: "error" });
          resolve(false);
        }
      } catch (error) {
        context.commit("SYNC");
        context.commit("SET_STATUS", { id: "error", text: error });
        resolve(false);
      }
    });
  },
  fetchScriptContent(context) {
    let activeScript = context.getters.activeScript;
    if (!activeScript || !activeScript.path) return;
    //===================================
    context.commit("SET_STATUS", { id: "loading", text: "loading" });
    let srv = new DashboardService();
    srv.getFileContent(activeScript.path).then((content) => {
      context.dispatch("validateContent", content);
    });
  },
  save(context) {
    return new Promise((resolve) => {
      let contract = context.rootGetters["user/contract"] || null;
      let activeScript = context.getters["activeScript"];
      let content = context.getters["content"];
      if (!content || !contract) {
        context.commit("SET_STATUS", { id: "idle", text: "" });
        resolve(null);
        return;
      }
      let payload = JSON.parse(JSON.stringify(scriptDefaults().newScreen));
      payload.content = JSON.parse(content);
      payload.contract_id = contract.id;
      // it saves whether there is a current script) 
      // otherwise it creates a new script 
      if (activeScript) {
        // compatible id
        if (activeScript.id) {
          payload.id = activeScript.id;
        }
        // compatible etag
        if (activeScript.etag) {
          payload.etag = activeScript.etag;
        }
        // increase version
        payload.revision_code = activeScript.revision_code
          ? (parseFloat(activeScript.revision_code || 1) + 0.01).toFixed(2)
          : "1.00";
      }
      context.commit("SET_STATUS", { id: "saving", text: "saving" });
      let srv = new ScreenService();
      srv.save(payload).then((resp) => {
        if (resp) {
          context.dispatch("dashboard/setScreen", resp, { root: true });
          context.commit("BUILD_GLOBAL_FUNCTIONS");
          context.commit("SET_STATUS", { id: "idle", text: "" });
        }
        resolve(resp);
      });
    });
  }
};

const getters = {
  content(state) {
    return state.content || "";
  },
  scriptList(state, getters, rootState, rootGetters) {
    return rootGetters["dashboard/scriptList"] || [];
  },
  activeScript(state, getters, rootState, rootGetters) {
    // Initially it only returns a single active main script
    // TODO: change it to returnScript editor
    let contract = rootGetters["user/contract"] || null;
    if (!contract) return null;
    let contractScriptIds = (contract?.portal_data?.scripts || []).map(
      ({ id }) => id
    );
    if (!contractScriptIds.length) return null;
    let scriptList = rootGetters["dashboard/scriptList"] || [];
    if (scriptList.length) {
      // it there is already a script list return it
      let lst = scriptList.filter(
        ({ id }) => contractScriptIds.indexOf(id) >= 0
      );
      return (lst.length && lst[state.activeIndex]) || null;
    } else {
      // otherwise return the contract one - it is enough for loading content
      return contract?.portal_data?.scripts[0];
    }
  },
  status(state) {
    return state.status;
  },
  globalFunctions(state) {
    return state.globalFunctions;
  },
  sync(state) {
    return state.sync;
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: mutations,
  actions: actions,
  getters: getters
};
