export default {
  data() {
    return {
      delayedValidation: null
    };
  },
  computed: {
    areRulesReady() {
      return this.$store.getters["user/isRulesReady"];
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (!this.isRouteAllowed(to)) {
          if (!this.areRulesReady) {
            this.delayedValidation = to;
          } else {
            this.$router.replace({
              path: to.path.replace(/\/\w+$/, "/unauthorized")
            });
          }
        }
      }
    },
    areRulesReady(ready) {
      if (
        ready &&
        this.delayedValidation &&
        !this.isRouteAllowed(this.delayedValidation)
      ) {
        this.$router.replace({
          path: this.delayedValidation.path.replace(/\/\w+$/, "/unauthorized")
        });
        this.delayedValidation = null;
      }
    }
  },
  methods: {
    isRouteAllowed(route) {
      return !route.matched.some(
        (r) => r.meta.permission && !this.$can("manage", r.meta.permission)
      );
    }
  }
};
