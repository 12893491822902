const updateListeners = new Set();
const propageUpdate = (newVal) => {
  // propate update to current listeners
  updateListeners.forEach((fn) => fn(newVal));
};
const mixin = {
  mounted() {
    // check if it is the root instance of the app
    if (this.$root == this && this.$el.matches("#app")) {
      // sets default feature config
      this.$featureToggle.setConfig(
        JSON.parse(JSON.stringify(this.config.feature_config))
      );
      // adds watcher for featureConfig of logged user
      // then updates featureToggle with it
      this.$store.watch(
        (_, getters) =>
          getters["user/loggedUser"]?.user_profile?.portal_data?.featureConfig,
        (userFeatureConfig) => {
          if (typeof userFeatureConfig == "object") {
            Object.keys(userFeatureConfig).forEach((feature) =>
              this.$featureToggle.setFeature(
                feature,
                userFeatureConfig[feature].enabled
              )
            );
            propageUpdate(userFeatureConfig);
          }
        }
      );

      this.$router.onReady(async () => {
        const route = this.$router.matcher.match(this.$route.path);
        // validates the matched routes (after initial navigation)
        for (let matched of route.matched) {
          if (!(await isRouteAllowed(matched, this))) {
            this.$router.replace("/dashboard");
            break;
          }
        }
      });
      this.$router.beforeEach((to, from, next) => {
        if (!to.matched.every((route) => isRouteAllowed(route, this))) {
          next(false);
        } else {
          next();
        }
      });
    }
  }
};

function isRouteAllowed(route, vm) {
  if (vm.$store.getters["user/loggedUser"])
    return (
      !route.meta.feature ||
      vm.$featureToggle.isFeatureEnabled(route.meta.feature)
    );
  else
    return new Promise((resolve) => {
      // returns a Promise that resolves once the user information is available
      let unwatch = vm.$store.watch(
        (_, getters) => getters["user/loggedUser"],
        (user) => {
          if (user) {
            resolve(
              !route.meta.feature ||
                vm.$featureToggle.isFeatureEnabled(route.meta.feature)
            );
            unwatch();
          }
        }
      );
    });
}

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, "$featureToggle", { value: this });
    Vue.mixin(mixin);
  },
  setConfig(featureConfig) {
    this.featureConfig = typeof featureConfig == "object" ? featureConfig : {};
  },
  setFeature(featureName, isEnabled) {
    // NOTE: This state is runtime only. To persist it change it in the user's portal_data
    this.featureConfig[featureName].enabled = isEnabled;
    propageUpdate(this.featureConfig);
  },
  isFeatureEnabled(featureName) {
    return this.featureConfig[featureName]?.enabled;
  },
  addUpdateListener(fn) {
    updateListeners.add(fn);
  },
  removeUpdateListener(fn) {
    updateListeners.delete(fn);
  }
};
